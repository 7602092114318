import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {createSetSavedAction, fetchSaved, setSaved} from "./saved.actions";
import {SavedService} from "../../services/api/saved/saved.service";


@Injectable()
export class SavedEffects {

  fetchSavedEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchSaved.type),
      mergeMap(() => this.savedService.getSaved()
        .pipe(map(res => {
          return createSetSavedAction(res.saved_slides, res.saved_documents)
        }))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private savedService: SavedService,
  ) {
  }

}
