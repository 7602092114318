<div class="chat-llm-container">
  <div class="chat-side-container" *ngIf="resetButtonText" >
    <div class="reset-button-container">
      <button class="reset-button transparent" icon="edit-contained"
              (click)="sendEventReset()" qm> {{ resetButtonText }}
      </button>
    </div>
  </div>
  <div class="chatbox-container">
    <textarea class="input-message"
              [maxlength]="questionMaxLength"
              [ngClass]="{'dark': isDarkMode, 'white': !isDarkMode}"
              [(ngModel)]="message"
              placeholder="{{placeholder}}"
              (keydown.enter)="sendEventMessage($event)"
              (ngModelChange)="checkTextAreaIsEmpty()"
              [disabled]="isAnswerPending"
              (keydown)="autoGrowTextZone($event)"
              qm
    ></textarea>
    <div class="prompt-footer caption">
      <div class="character-count">
        {{ message ? message.length : 0 }}/{{ questionMaxLength }}
      </div>
    </div>
    <div class="button-container">
      <button class="send-button" icon="send-01"
              (click)="sendEventMessage($event)" [disabled]="isTextAreaEmpty" qm></button>
    </div>
  </div>

</div>
