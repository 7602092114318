import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  AssetType,
  GetIkhProposalsMetadataResponse, PatchIkhProposalContactPerson,
  PostIKHProposalsMetadata,
  PostIKHProposalsPreUploadLinkRequest,
  PostIKHProposalsPreUploadResponse,
} from "./model/model";
import {AssetElement} from "../slide-finder/model/model";


@Injectable({
  providedIn: 'root'
})
export class IkhProposalsService {

  constructor(
    public http: HttpClient
  ) {
  }

  postIkhProposalPreUpload(file: File): Observable<PostIKHProposalsPreUploadResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<PostIKHProposalsPreUploadResponse>(environment.apiUrl + '/ikh-proposal/pre-upload', formData);
  }

  postIkhProposalsPreUploadLink(body: PostIKHProposalsPreUploadLinkRequest): Observable<PostIKHProposalsPreUploadResponse> {
    return this.http.post<PostIKHProposalsPreUploadResponse>(environment.apiUrl + '/ikh-proposal/pre-upload-link', body);
  }

  postIkhProposalUpload(correlation_id: string, filename: string, metadata: PostIKHProposalsMetadata, asset_type: AssetType): Observable<any> {
    return this.http.post(environment.apiUrl + "/ikh-proposal", {
      correlation_id,
      filename,
      metadata,
      asset_type,
    });
  }

  getIkhProposalMetadata(): Observable<GetIkhProposalsMetadataResponse> {
    return this.http.get<GetIkhProposalsMetadataResponse>(environment.apiUrl + "/ikh-proposal/metadata");
  }

  getIkhProposalSlideThumbnail(unique_id: string, slide_index: number, large: boolean): Observable<Blob> {
    return this.http.get(
      environment.apiUrl + "/ikh-proposal/" + unique_id + "/thumbnails/" + slide_index + "?large=" + large,
      {responseType: 'blob'}
    );
  }

  patchIkhProposalContributors(doc_id: number, body: PatchIkhProposalContactPerson): Observable<void> {
    return this.http.patch<void>(environment.apiUrl + "/ikh-proposal/" + doc_id + "/contributors", body);
  }

  getIkhProposalByContributor(email: string): Observable<AssetElement[]> {
    return this.http.get<AssetElement[]>(environment.apiUrl + "/ikh-proposal/" + email);
  }

}
