import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Utils} from "../../utils/utils";
import {catchError, of, switchMap} from "rxjs";
import {emptyUserImageBase64} from "../../utils/image.utils";

export interface AadUserType {
  displayName: string;
  givenName: string;
}

@Component({
  selector: 'app-azure-badge',
  templateUrl: './azure-badge.component.html',
  styleUrls: ['./azure-badge.component.scss']
})
export class AzureBadgeComponent implements AfterViewInit, OnChanges {

  @Input() userOid: string;
  @Input() displayName = false;
  @Input() title = "";
  @Input() displayDeleteButton = false;
  @Input() pictureStyle: { [key: string]: string } = {};

  @Output() userLoaded = new EventEmitter<unknown>();
  @Output() deleteUser: EventEmitter<string> = new EventEmitter<string>();


  picture: string;
  loaded: boolean;
  name: string;
  fullName: string;
  utils = new Utils();
  constructor(
    public http: HttpClient
  ) {
  }

  getKey(): string {
    return "aad_key_" + this.userOid;
  }

  clearValue(): void {
    localStorage.removeItem(this.getKey());
  }

  putValue(): void {
    if (this.name && this.fullName) {
      localStorage.setItem(this.getKey(), JSON.stringify({
        name: this.name,
        fullName: this.fullName,
        picture: this.picture || emptyUserImageBase64,
        expiryDate: new Date()
      }));
      this.setLoaded();
    }
  }


  ngAfterViewInit(): void {
    this.changeUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userOid'] && changes['userOid'].currentValue != changes['userOid'].previousValue) {
      this.changeUser();
    }
  }


  changeUser(): void {
    const element = localStorage.getItem(this.getKey());
    if (element) {
      const data = JSON.parse(element);
      const expiryDate = new Date(data.expiryDate);
      if (new Date().getTime() - expiryDate.getTime() > 1000 * 60 * 60 * 24 * 30 || !data.picture || !data.name || !data.fullName) {
        this.clearValue();
        this.fetchAzureDate();
      } else {
        this.name = data.name;
        this.fullName = data.fullName;
        this.picture = data.picture;
        setTimeout(() => {
          this.setLoaded();
        }, 1);
      }
    } else {
      this.fetchAzureDate();
    }
  }

  fetchAzureDate(): void {
    this.http.get<AadUserType>("https://graph.microsoft.com/v1.0/users/" + this.userOid).pipe(
      catchError(() => {
        return of({
          displayName: "Unknown User",
          givenName: "Unknown User",
        });
      })
    ).subscribe(data => {
      if (data.givenName) {
        this.name = data.givenName;
      } else if (data.displayName) {
        this.name = data.displayName.split(".").map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(" ");
      } else {
        this.name = "Unknown User";
      }
      this.fullName = data.displayName;
      this.putValue();
    });
    this.http.get("https://graph.microsoft.com/v1.0/users/" + this.userOid + "/photo/$value", {responseType: 'blob'}).pipe(
      switchMap(data => this.utils.blobToBase64(data, () => this.setLoaded())),
      catchError(() => [emptyUserImageBase64])
    ).subscribe(data => {
      this.picture = data;
      this.putValue();
    });
  }

  setLoaded(): void {
    this.loaded = true;
    this.userLoaded.emit();
  }

  deleteUserOnClick(): void {
    this.deleteUser.emit(this.userOid);
  }
}
