<div *ngIf="rfpDetails?.documents?.length === 0"> Add documents to retrieve the most related documents.</div>
<app-asset-elements-container *ngIf="rfpDetails?.rfp?.abstract_doc_finder"
                              [assetElements]="assets"
                              [pending]="isPending"
                              [isLoadingMoreElements]="isLoadingMoreElements"
                              [mode]="'Asset Retriever'"
                              [yearFilter]="year_filter"
                              [displayUpload]="false"
                              (getNext)="getMoreElements()"
                              (logOpenDocument)="logOpenDocument($event)"
                              (viewDetails)="viewElementDetails($event)"
></app-asset-elements-container>
<div class="asset-retriever-top-container" *ngIf="rfpDetails?.documents?.length !== 0">
  <div></div>
  <button
          class="secondary seeMoreButton"
          iconPosition="right"
          icon="arrow-right"
          qm
          (click)="navigateToAssetRetriever()"
  >
    See more in doc finder
  </button>
</div>
