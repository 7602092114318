import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {GetSavedIkhDocsResponse, GetSavedResponse} from "./model/model";


@Injectable({
  providedIn: 'root'
})
export class SavedService {

  constructor(
    public http: HttpClient
  ) {
  }

  postSaveIkhDoc(document_id: number): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/saved/docs', {document_id});
  }

  postSaveIkhSlide(document_id: number, slide_number: number): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/saved/slides', {document_id, slide_number});
  }

  deleteSavedIkhDoc(document_id: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/saved/docs?document_id=' + document_id);
  }

  deleteSavedIkhSlide(document_id: number, slide_number: number): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + '/saved/slides?document_id=' + document_id + '&slide_number=' + slide_number);
  }

  getSaved(): Observable<GetSavedResponse> {
    return this.http.get<GetSavedResponse>(environment.apiUrl + '/saved');
  }

  getSavedIkhDocs(): Observable<GetSavedIkhDocsResponse> {
    return this.http.get<GetSavedIkhDocsResponse>(environment.apiUrl + '/saved/docs');
  }

  getSavedIkhSlides(): Observable<GetSavedIkhDocsResponse> {
    return this.http.get<GetSavedIkhDocsResponse>(environment.apiUrl + '/saved/slides');
  }

}
