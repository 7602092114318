<div *ngIf="!expandedUser" class="main-container">
  <app-header
          [headerTitle]="'Expert Finder'"
          [headerDescription]="'Find the right expert in Invent France to help boost your productivity.'"
          [headerBackgroundColor]="'expertFinder'"
          [minimized]="displayResults"
  >
  </app-header>

  <div class="bottom-chat-container">
    <div *ngIf="!isPending && !query_abstract" class="middle-bottom-container">
      <div class="empty-container"></div>
      <div *ngIf="!lastSearchReturnedError" class="middle-bottom-text">
        <div>
          Find the experts you need by doing a natural<br/><br/> language search in the chat below.
        </div>
      </div>
      <div *ngIf="lastSearchReturnedError" class="middle-bottom-text error">
        <span class="qm-icon-alert-circle" style="height: 35px"></span>
        Just AI was unable to find any expert. Please, try another search.
      </div>
      <div class="empty-container">
      </div>
    </div>

    <div style="margin-bottom: 70vh">
      <div class="results-container">
        <ng-container *ngFor="let user of experts">
          <app-profile-snippet
                  [user]="user"
                  (viewDetails)="viewElementDetails($event)"
          >

          </app-profile-snippet>
        </ng-container>
      </div>

      <div class="more-elements">
        <div *ngIf="isLoadingMoreElements" class="spinner">
          <qmspinner *ngIf="isLoadingMoreElements"></qmspinner>
        </div>
        <span (click)="getMoreExperts()"
              *ngIf="displayResults && !isLoadingMoreElements && !isPending">
        Search more experts
      </span>
      </div>
      <div *ngIf="displayResults && !isLoadingMoreElements && !isPending" class="profile-link">
        <div class="upload-help-text body2 medium" style="margin-bottom: 12px;">
          Wondering how to boost your own profile? Enrich your information and tag yourself on more content
        </div>
        <button
                qm
                class="secondary"
                (click)="navigateToProfilePage()"
        >
          Check my profile
        </button>
      </div>
    </div>

    <app-search-asset-elements
            [resetButtonText]="resetButtonText"
            [isPending]="isPending"
            [placeholder]="placeholder"
            [query]="display_query"
            (sendMessage)=postExpertFinder($event)
            (reset)=resetSearch()
            (refine)="refineSearch()"
    >
    </app-search-asset-elements>
  </div>
</div>

<app-user-view-details
        *ngIf="expandedUser"
        [user]="expandedUser.user"
        [backText]="'Back to expert finder'"
        (closeDetailsPage)="backToPrincipalPage()"

>

</app-user-view-details>
