import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-chat-llm',
  templateUrl: './chat-llm.component.html',
  styleUrls: ['./chat-llm.component.scss']
})
export class ChatLlmComponent implements OnInit {


  message: string = "";
  isTextAreaEmpty: boolean = true;
  isAnswerPending: boolean = false;

  @Input() questionMaxLength: number;
  @Input() checkFilterYear = "";
  @Input() areFilterDateRadiosDisabled: boolean = false;
  @Input() placeholder: string;
  @Input() resetButtonText?: string;
  @Input() areFilterDateRadiosDisplayed: boolean = true;
  @Input() disabledYearFilter: boolean;
  @Input() isDarkMode: boolean = true;
  @Input() canSendMessage: boolean = true;
  @Output() sendMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendFilterYear: EventEmitter<string> = new EventEmitter<string>();
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
  }

  clearTextArea() {
    this.message = '';
    this.isTextAreaEmpty = true;
  }

  sendEventMessage(event: any){
    if (event.keyCode === 13) {
      event.preventDefault();
    }
    if (!this.canSendMessage) {
      return;
    }
    this.checkTextAreaIsEmpty();
    if (!this.isTextAreaEmpty) {
      this.sendMessage.emit(this.message)
      if (this.checkFilterYear !== '') {
        this.sendFilterYear.emit(this.checkFilterYear)
      }
      this.clearTextArea();
      this.areFilterDateRadiosDisabled = true;
    }

  }

  autoGrowTextZone(e: any) {
    e.target.style.height = "0";
    e.target.style.height = (e.target.scrollHeight)+"px";
    e.target.style.maxHeight = '152px'
  }

  resetComponent() {
    this.areFilterDateRadiosDisabled = false;
    this.checkFilterYear = "";
    this.clearTextArea();
  }

  sendEventReset(){
    this.reset.emit();
    this.resetComponent();
  }

  checkTextAreaIsEmpty() {
    this.isTextAreaEmpty = !(this.message.length > 0);
  };

  setFilterYear(year: string) {
    if (!this.areFilterDateRadiosDisabled){
      if (this.checkFilterYear === year) {
        this.checkFilterYear = "";
      } else
      this.checkFilterYear = year;
    }
  }

}
