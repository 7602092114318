import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserElementWithIndex, UserProfile} from "../../services/api/user-profile/model/model";
import {Utils} from "../../utils/utils";
import {catchError, switchMap} from "rxjs";
import {emptyUserImageBase64} from "../../utils/image.utils";
import {HttpClient} from "@angular/common/http";
import {AssetElement} from "../../services/api/slide-finder/model/model";

@Component({
  selector: 'app-profile-snippet',
  templateUrl: './profile-snippet.component.html',
  styleUrls: ['./profile-snippet.component.scss']
})
export class ProfileSnippetComponent implements OnInit {
  @Input() assetElements: AssetElement[];
  @Input() user: UserProfile;
  @Output() viewDetails: EventEmitter<UserElementWithIndex> = new EventEmitter<UserElementWithIndex>();
  picture: string;
  utils = new Utils();
  loaded: boolean;

  constructor(public http: HttpClient) {
  }

  ngOnInit(): void {
    this.retrieveUserPicture();
  }

  retrieveUserPicture() {
    this.http.get("https://graph.microsoft.com/v1.0/users/" + this.user.user_oid + "/photo/$value", {responseType: 'blob'}).pipe(
      switchMap(data => this.utils.blobToBase64(data, () => this.setLoaded())),
      catchError(() => [emptyUserImageBase64])
    ).subscribe(data => {
      this.picture = data;
    });
  }

  setLoaded(): void {
    this.loaded = true;
  }

  onContactClick(event: Event): void {
    event.stopPropagation();
    this.contactExpert(this.user.email);
  }

  contactExpert(email: string): void {
    window.open('msteams://teams.microsoft.com/l/chat/0/0?users=' + email, '_blank');
  }
}
