<app-header
        [headerTitle]="'Upload DocS'"
        [headerDescription]="'Upload documents to the Invent Knowledge Hub'"
        [headerBackgroundColor]="'uploadDocuments'"
>
</app-header>

<div class="upload-doc-container" [ngClass]="{'pending': opportunityDataPending}">
  <div class="header-container">
    <h4 class="title medium">Document type</h4>
    <div class="upload-button" *ngIf="popinState!==PopinState.UploadingFile">
      <qminfo class="qminfo-container"
              *ngIf="isAssetReady() && !isReadOnly"
              [text]="'Well done! Your asset is publishable, you can upload it'"></qminfo>

      <div class="button-group">
        <button qm *ngIf="isReadOnly" class="medium secondary cancel-button"
                (click)="cancelReadOnlyMode()"> Cancel
        </button>

        <button qm class="share-button"
                *ngIf="!isReadOnly"
                [disabled]="!isAssetReady()"
                (click)="enterReadOnlyMode()"> Upload
        </button>
        <button qm class="share-button"
                *ngIf="isReadOnly"
                (click)="confirmUpload()"> Confirm
        </button>
      </div>
    </div>
    <div *ngIf="popinState===PopinState.UploadingFile">
      <qmspinner></qmspinner>
    </div>

  </div>
  <div class="subtitle-container desktop-body2-regular" *ngIf="!isReadOnly">
    <div>Choose the type of document you want to upload: </div>
  </div>

  <div class="main-container" *ngIf="!isReadOnly">

    <div class="left-container">
      <div
              [class]="'radio-button-container ' + (selectedTrackAsset === AssetType.ProjectAsset? 'is-radio-selected': '')">
        <qmradios-button id="project-asset" (click)="selectAssetType(AssetType.ProjectAsset)"
                         [checked]="selectedTrackAsset===AssetType.ProjectAsset" [withoutBorders]="true">
          <div class="radio-button-text">
            <div class="radio-button-text-head-title">
              Project asset
            </div>
            <div class="radio-button-text-detail">
              Proposal, credential, deliverable specific to a project
            </div>
          </div>
        </qmradios-button>
      </div>
      <div
              [class]="'radio-button-container ' + (selectedTrackAsset === AssetType.CompanyAsset? 'is-radio-selected': '')">
        <qmradios-button id="company-asset" (click)="selectAssetType(AssetType.CompanyAsset)"
                         [checked]="selectedTrackAsset===AssetType.CompanyAsset" [withoutBorders]="true">
          <div class="radio-button-text">
            <div class="radio-button-text-head-title">
              Company asset
            </div>
            <div class="radio-button-text-detail">
              Study, method, offer, training not related to a specific project
            </div>
          </div>
        </qmradios-button>
      </div>
      <div class="container-upload-file">
        <div class="desktop-h3-medium">Upload documents</div>
        <div class="desktop-body2-regular" style="padding: 24px 0">Then, select one by one the documents you want to upload:</div>
      </div>

      <qmdragdrop class="dragdrop"
                  [allowReUpload]=true
                  [addImageText]="addImageText"
                  [addFormatText]="addFormatText"
                  (changePicture)="onFileInput($event)"
                  [disabled]="!selectedTrackAsset"
      >
      </qmdragdrop>

      <div *ngFor="let f of files; index as i" style="margin-bottom: 16px">
        <qmfilesnippet [file]="f.file"
                       [isPending]="!f.isPreUploaded"
                       (deleting)="removeFileFromList(i)"
                       [editIcon]="false"
        >
          <div class="files-categories-dropdown">
            <div class="header-dropdown" (click)="openDropDown(i)">
              <div class="body3" *ngIf="!f.fileCategory"> Select Category</div>
              <div class="body3" *ngIf="f.fileCategory"> {{ f.fileCategory }}</div>
              <span class="qm-icon-chevron-down chevron-style"></span>
            </div>
            <div class="menu-dropdown" *ngIf="isDropdown[i]">
              <div class="menu-dropdown-items body3" *ngFor="let cat of fileCategories"
                   (click)="selectFileCategory(i, cat)">
                {{ cat }}
              </div>
            </div>
          </div>
        </qmfilesnippet>
      </div>
    </div>

    <div class="right-container">

      <div id="asset-details" class="details-container" [ngClass]="{'completed': checkCompletedAssetDetails()}">
        <div class="details-titles">
          <div class="desktop-h3-medium"> Documents details</div>
          <div class="chevron-container" (click)="assetDetailOpened=true" *ngIf="!assetDetailOpened && selectedTrackAsset"><span class="qm-icon-chevron-down"></span></div>
          <div class="chevron-container" (click)="assetDetailOpened=false" *ngIf="assetDetailOpened"><span class="qm-icon-chevron-up"></span></div>
        </div>

        <div *ngIf="assetDetailOpened">
          <div class="desktop-body2-regular details-content" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
            Connect the documents to an opportunity
          </div>

          <div class="input-row" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">

            <div class="input-block">

              <div class="input-title desktop-body2-regular ">Opportunity ID<span class="stars">*</span></div>
              <input qm
                     [ngModel]="searchValue"
                     (ngModelChange)="onChangeOpportunitySearch($event)"
                     (keyup)="opportunityDropDown=true"
                     placeholder="Search by Opportunity ID, Account or Project Name"
                     [class.invalid]="!form['opportunity_id'].valid"
                     class="desktop-body2-medium">

              <div class="dropdown-menu" *ngIf="opportunityDropDown">
                <div *ngFor="let opportunity of getSearchedOpportinuties()"
                     (click)="selectOpportunity(opportunity)"
                     class="dropdown-element">
                  <div class="left-block body3">
                    OP# {{ opportunity.opportunity_id }}
                  </div>
                  <div class="body3 account">
                    {{ opportunity.account }}
                  </div>
                  <div
                          class="right-block body3 text-cropped-twolines"
                          style="margin-bottom: 0;"
                  >
                    {{ opportunity.opportunity_name }}
                  </div>
                </div>
                <div class="dropdown-element body3" *ngIf="!pendingSearchOpportunity && getSearchedOpportinuties().length === 0">OP# {{searchValue}}</div>
                <div class="dropdown-element body3" *ngIf="pendingSearchOpportunity">
                  <qmspinner style="margin: auto"></qmspinner>
                </div>
              </div>
            </div>

            <div class="input-block">
              <div class="input-title desktop-body2-regular ">Project title<span class="stars">*</span></div>
              <input qm
                     [(ngModel)]="form['title'].content"
                     (change)="form['title'].valid=true"
                     placeholder="Project title"
                     [class.invalid]="!form['title'].valid"
                     class="desktop-body2-medium">
            </div>
          </div>


          <div class="input-row">
            <div class="input-block" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
              <div class="input-title desktop-body2-regular ">Account<span class="stars">*</span></div>
              <input qm
                     [(ngModel)]="form['account'].content"
                     (change)="form['account'].valid=true"
                     placeholder="Account"
                     [class.invalid]="!form['account'].valid"
                     class="desktop-body2-medium">
            </div>


            <div class="input-block">
              <div class="input-title desktop-body2-regular" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">Project
                start date<span class="stars">*</span>
              </div>
              <div class="input-title desktop-body2-regular" *ngIf="selectedTrackAsset===AssetType.CompanyAsset">Asset
                creation date<span class="stars">*</span>
              </div>
              <input qm
                     [(ngModel)]="form['created_date'].content"
                     (change)="form['created_date'].valid=true"
                     placeholder="dd/mm/yyyy"
                     [class.invalid]="!form['created_date'].valid"
                     class="desktop-body2-medium">
            </div>
          </div>


          <div class="input-row">
            <div class="input-block">
              <div class="input-title desktop-body2-regular ">Industry<span class="stars">*</span></div>
              <div class="dropdown">
                <input qm
                       [(ngModel)]="form['industry'].content"
                       [class.invalid]="!form['industry'].valid"
                       class="desktop-body2-medium"
                       (focus)="form['industry'].showDropdown = true">
                <div *ngIf="form['industry'].showDropdown" class="dropdown-menu">
                  <div
                          *ngFor="let industry of getSearchedIndustries()"
                          (click)="selectFieldValue('industry', industry)"
                          class="dropdown-element">
                    {{ industry }}
                  </div>
                </div>
              </div>
            </div>

            <div class="input-block">
              <div class="input-title desktop-body2-regular ">Capability unit<span class="stars">*</span></div>
              <div class="dropdown">
                <input qm
                       [(ngModel)]="form['capability_unit'].content"
                       (change)="form['capability_unit'].valid=true"
                       [class.invalid]="!form['capability_unit'].valid"
                       class="desktop-body2-medium"
                       (focus)="form['capability_unit'].showDropdown = true">
                <div *ngIf="form['capability_unit'].showDropdown" class="dropdown-menu right-position">
                  <div
                          *ngFor="let capability_unit of getSearchedCapabilityUnits()"
                          (click)="selectFieldValue('capability_unit', capability_unit)"
                          class="dropdown-element">
                    {{ capability_unit }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="input-row">
            <div class="input-block">
              <div class="input-title desktop-body2-regular">Capability unit L1<span class="stars">*</span></div>
              <div class="dropdown">
                <input qm
                       [(ngModel)]="form['capability_unit_l1'].content"
                       (change)="form['capability_unit_l1'].valid=true"
                       [class.invalid]="!form['capability_unit_l1'].valid"
                       class="desktop-body2-medium"
                       (focus)="form['capability_unit_l1'].showDropdown = true">
                <div *ngIf="form['capability_unit_l1'].showDropdown" class="dropdown-menu">
                  <div
                          *ngFor="let capability_unit_l1 of getSearchedCapabilityUnitsL1()"
                          (click)="selectFieldValue('capability_unit_l1', capability_unit_l1)"
                          class="dropdown-element">
                    {{ capability_unit_l1 }}
                  </div>
                </div>
              </div>
              <div class="input-block"></div>
            </div>

          </div>
          <div class="desktop-body3-regular"><span class="stars">*</span> Mandatory fields</div>

        </div>
      </div>

      <div id="permission-status" class="details-container" [ngClass]="{'completed': isSecStatusFilled}">
        <div class="details-titles">
          <div class="desktop-h3-medium">Permission status</div>
          <div class="chevron-container" (click)="isSecOpen=true" *ngIf="!isSecOpen && selectedTrackAsset"><span class="qm-icon-chevron-down"></span></div>
          <div class="chevron-container" (click)="isSecOpen=false" *ngIf="isSecOpen"><span class="qm-icon-chevron-up"></span></div>
        </div>
        <div *ngIf="isSecOpen">

          <div class="body2 regular sec-tooltip-container"> <span class="qm-icon-information-circle-contained sec-tooltip-icon"> </span>
            Define the SEC status of your documents
            <pre class="sec-tooltip-description caption medium"> {{secText}}</pre>
          </div>

          <div class="sec-buttons">
            <button qm class="secondary" (click)="selectSecStatus(0)"
                    [ngClass]="{'clicked': secStatusItems[0].isSelected}">
              <div class="sec-chip sec-chip-color-green"></div>
              SEC 0
            </button>
            <button qm class="secondary" (click)="selectSecStatus(1)"
                    [ngClass]="{'clicked': secStatusItems[1].isSelected}">
              <div class="sec-chip sec-chip-color-orange"></div>
              SEC 1
            </button>
            <button qm class="secondary" (click)="selectSecStatus(2)"
                    [ngClass]="{'clicked': secStatusItems[2].isSelected}">
              <div class="sec-chip sec-chip-color-red"></div>
              SEC 2
            </button>
          </div>
        </div>
      </div>

      <div id="contributors" class="details-container" [ngClass]="{'completed': checkCompletedContributors()}">
        <div class="details-titles">
          <div class="desktop-h3-medium">Contributors</div>
          <div class="chevron-container" (click)="isContributorsOpen=true" *ngIf="!isContributorsOpen && selectedTrackAsset"><span class="qm-icon-chevron-down"></span></div>
          <div class="chevron-container" (click)="isContributorsOpen=false" *ngIf="isContributorsOpen"><span class="qm-icon-chevron-up"></span></div>
        </div>
        <div *ngIf="isContributorsOpen">
          <div style="margin-bottom: 24px">Provide name of contributors for the documents</div>
          <div>
          </div>
          <app-manage-contributors
                  [opportunityLead]="selectedLead.user_name"
                  [contributorUsers]="selectedContributors"
                  [isProjectAsset]="selectedTrackAsset===AssetType.ProjectAsset"
                  (sendLead)="handleLead($event)"
                  (sendContributors)="handleContributors($event)"
          >

          </app-manage-contributors>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="isReadOnly" class="summary-block">
    <div class="summary-left-container">
      <div class="radio-button-container read-only" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
        <div class="radio-button-text">
          <div class="radio-button-text-head-title">
            Project asset
          </div>
          <div class="radio-button-text-detail">
            Proposal, credential, deliverable
          </div>
        </div>
      </div>
      <div class="radio-button-container read-only" *ngIf="selectedTrackAsset===AssetType.CompanyAsset">
        <div class="radio-button-text">
          <div class="radio-button-text-head-title">
            Company asset
          </div>
          <div class="radio-button-text-detail">
            Not related to specific project (study, methods, offers, trainings...)
          </div>
        </div>
      </div>
      <div class="Uploaded-asset-container">
        <h4 class="medium"> Uploaded documents</h4>
        <div *ngFor="let f of files; index as i" style="margin-bottom: 16px">
          <qmfilesnippet [file]="f.file"
                         [editIcon]="false"
                         [trashIcon]="false"
          >
            <div class="file-category body2 medium">{{ f.fileCategory }}</div>
          </qmfilesnippet>
        </div>

      </div>
    </div>
    <div class="summary-right-container">
      <div class="asset-details-display">
        <h4 class="medium" style="margin: 0">
          Documents Details
        </h4>
        <div class="row-container" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">

          <div class="column-container">
            <div class="body2">Opportunity ID</div>
            <div class="body2 bold">{{ form["opportunity_id"].content }}</div>
          </div>

          <div class="column-container right-side" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
            <div class="body2">Project Title</div>
            <div class="body2 bold">{{ form["title"].content }}</div>
          </div>
        </div>
        <div class="row-container">
          <div class="column-container" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
            <div class="body2">Account</div>
            <div class="body2 bold">{{ form["account"].content }}</div>
          </div>
          <div class="column-container" *ngIf="selectedTrackAsset===AssetType.CompanyAsset">
            <div class="body2">Industry</div>
            <div class="body2 bold">{{ form["industry"].content }}</div>
          </div>
          <div class="column-container right-side">
            <div class="body2" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">Project Start Date</div>
            <div class="body2" *ngIf="selectedTrackAsset===AssetType.CompanyAsset">Asset Creation Date</div>
            <div class="body2 bold">{{ form["created_date"].content }}</div>
          </div>


        </div>
        <div class="row-container">
          <div class="column-container" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
            <div class="body2">Industry</div>
            <div class="body2 bold">{{ form["industry"].content }}</div>
          </div>

          <div class="column-container" *ngIf="selectedTrackAsset===AssetType.CompanyAsset">
            <div class="body2">Capability unit L1</div>
            <div class="body2 bold">{{ form["capability_unit_l1"].content }}</div>
          </div>

          <div class="column-container right-side">
            <div class="body2">Capability unit</div>
            <div class="body2 bold">{{ form["capability_unit"].content }}</div>
          </div>

        </div>

        <div class="row-container">
          <div class="column-container" *ngIf="selectedTrackAsset===AssetType.ProjectAsset">
            <div class="body2">Capability unit L1</div>
            <div class="body2 bold">{{ form["capability_unit_l1"].content }}</div>
          </div>
        </div>

      </div>
      <div class="sec-status-display">
        <h4 class="medium" style="margin:24px 0">Permission Status</h4>
        <div class="sec-status-container">
          <div [class]="['sec-chip', 'sec-chip-color-' + getSecStatusDescriptionAndColor()?.color]"></div>
          <div class="sec-status body2 bold">{{ this.form["sec_status"].content }}</div>
        </div>
      </div>
      <div class="contributors-display">
        <h4 class="medium" style="margin:24px 0">Contributors</h4>
        <div *ngIf="selectedTrackAsset === AssetType.ProjectAsset">
          <div class="body2">Opportunity Lead</div>
          <div class="people">
            <app-azure-badge
                    [displayName]="true"
                    [userOid]="selectedLead.user_oid">
            </app-azure-badge>
          </div>
        </div>


        <div class="body2">Contributors</div>
        <div class="people">
          <app-azure-badge *ngFor="let contributor of selectedContributors"
                           [displayName]="true"
                           [userOid]="contributor.user_oid">
          </app-azure-badge>
        </div>


      </div>

    </div>
  </div>
</div>

<qmpopin
        *ngIf="popinState === PopinState.Done"
        [isCrossAvailable]="true"
        (close)="goToHomePage()"
>
  <div class="popin-container">
    <span class="qm-icon-check check-style"></span>
    <h2 class="medium" style="margin: 0">
      Document uploaded
    </h2>
    <div class="body2 bold">
      Feeling generous? Add another asset
    </div>
    <div class="popin-button-container">
      <button qm class="secondary" (click)="goToHomePage()">Next time</button>
      <button qm (click)="reloadPage()">Add New Asset</button>
    </div>
  </div>
</qmpopin>
