import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {MarkdownModule} from 'ngx-markdown';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {UiComponentsModule} from "@quantmetry/ui-components";
import {MsalInterceptor, MsalModule, MsalRedirectComponent} from "@azure/msal-angular";
import {InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomePageComponent} from './pages/home-page/home-page.component';
import {environment} from "../environments/environment";
import {SecureChatGptComponent} from "./pages/secure-chatgpt/secure-chatgpt.component";
import {FormsModule} from "@angular/forms";
import {NewAssetPageComponent} from './pages/new-asset-page/new-asset-page.component';
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {reducers} from "./store/reducers";
import {effects} from "./store/effects";
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { SlideFinderPageComponent } from './pages/slide-finder-page/slide-finder-page.component';
import {DocFinderPageComponent} from "./pages/doc-finder-page/doc-finder-page.component";
import {ChatLlmComponent} from "./components/chat-llm/chat-llm.component";
import {SearchAssetElementsComponent} from "./components/search-asset-elements/search-asset-elements.component";
import {
  AssetElementsContainerComponent
} from "./components/asset-elements-container/asset-elements-container.component";
import {AssetElementSnippetComponent} from "./components/asset-element-snippet/asset-element-snippet.component";
import { AssetElementDetailsComponent } from './components/asset-element-details/asset-element-details.component';
import {NgOptimizedImage} from "@angular/common";
import { RfpAssistantComponent } from './pages/rfp-assistant/rfp-assistant.component';
import { HeaderComponent } from './components/header/header.component';
import { RfpDetailsComponent } from './pages/rfp-assistant/rfp-details/rfp-details.component';
import { RfpProvideInfoComponent } from './pages/rfp-assistant/rfp-details/rfp-provide-info/rfp-provide-info.component';
import {RfpEditTextComponent} from "./pages/rfp-assistant/rfp-details/rfp-edit-text/rfp-edit-text.component";
import { RfpAskQuestionComponent } from './pages/rfp-assistant/rfp-details/rfp-ask-question/rfp-ask-question.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ManageContributorsComponent } from './components/manage-contributors/manage-contributors.component';
import { AzureBadgeComponent } from './components/azure-badge/azure-badge.component';
import { ExpertFinderComponent } from './pages/expert-finder/expert-finder.component';
import { ProfileSnippetComponent } from './components/profile-snippet/profile-snippet.component';
import { UserViewDetailsComponent } from './components/user-view-details/user-view-details.component';
import { RfpExpertFinderComponent } from './pages/rfp-assistant/rfp-details/rfp-expert-finder/rfp-expert-finder.component';
import { RfpDocFinderComponent } from './pages/rfp-assistant/rfp-details/rfp-doc-finder/rfp-doc-finder.component';
import { SavedAssetsComponent } from './pages/saved-assets/saved-assets.component';



export const protectedResources: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0', ['user.read']],
  ['http://localhost:8000', ['api://27358040-c27a-4d63-9dd2-361224678e11/api.read']],
  [environment.apiUrl, ['api://27358040-c27a-4d63-9dd2-361224678e11/api.read']],
];

let msalInstance = new PublicClientApplication({
  auth: {
    // This is your client ID
    clientId: 'c3139912-2ac4-4d6a-8f01-f5a7c9f77f2f',
    // The GUID is your Directory (or tenant) ID
    authority: 'https://login.microsoftonline.com/76a2ae5a-9f00-4f6b-95ed-5d33d77c4d61',
    // This is your redirect URI
    redirectUri: environment.redirectUri
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
  },
});

let guardConfig = {
  interactionType: InteractionType.Redirect, // MSAL Guard Configuration
  authRequest: {
    scopes: ['user.read']
  }
}

let interceptorConfig = {
  interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
  protectedResourceMap: new Map(protectedResources)
};

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SecureChatGptComponent,
    NewAssetPageComponent,
    AdminPageComponent,
    SlideFinderPageComponent,
    DocFinderPageComponent,
    ChatLlmComponent,
    SearchAssetElementsComponent,
    AssetElementsContainerComponent,
    AssetElementSnippetComponent,
    AssetElementDetailsComponent,
    RfpAssistantComponent,
    HeaderComponent,
    RfpDetailsComponent,
    RfpProvideInfoComponent,
    RfpEditTextComponent,
    RfpAskQuestionComponent,
    ProfilePageComponent,
    ManageContributorsComponent,
    AzureBadgeComponent,
    ExpertFinderComponent,
    ProfileSnippetComponent,
    UserViewDetailsComponent,
    RfpExpertFinderComponent,
    RfpDocFinderComponent,
    SavedAssetsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiComponentsModule,
    HttpClientModule,
    // @ts-ignore
    MsalModule.forRoot(msalInstance, guardConfig, interceptorConfig),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument(),
    BrowserAnimationsModule,
    FormsModule,
    NgOptimizedImage,
    MarkdownModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
