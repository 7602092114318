
import {StoreState} from "../reducers";
import {createSelector} from "@ngrx/store";
import {UserProfile} from "../../services/api/user-profile/model/model";
import {UsersState} from "./users.reducer";

const getUsersStore = (state: StoreState) => state.users;

export const selectUsers: (state: any) => UserProfile[] = createSelector(
  getUsersStore,
  (state: UsersState) => Object.values(state.users)
);


export const selectSpecificUser: (state: any, email: string) => UserProfile | undefined = createSelector(
  getUsersStore,
  (state: UsersState, email: string) => state.users[email]
)
