<div *ngIf="isPending" class="spinner-container">
  <qmspinner></qmspinner>
</div>
<div class="container">
  <div *ngIf="lastSearchReturnedError" class="middle-bottom-text error">
    <span class="qm-icon-alert-circle" style="height: 35px"></span>
     An error occurs when finding experts.
  </div>
  <ng-container *ngFor="let user of experts">
    <app-profile-snippet
            [user]="user"
            (viewDetails)="viewElementDetails($event)"
    >
    </app-profile-snippet>
  </ng-container>
</div>
<div class="expert-finder-top-container" *ngIf="experts.length > 0">
  <div></div>
  <button
          class="secondary seeMoreButton"
          iconPosition="right"
          icon="arrow-right"
          qm
          (click)="navigateToExpertFinder()"
  >
    See more in expert finder
  </button>
</div>
