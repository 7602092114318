<div class="container" (click)="viewDetails.emit({user: user})">
  <div class="title-container">
    <div *ngIf="picture" class="picture-container">
      <img [src]="picture" alt="profile picture">
    </div>
    <div class="info-container">
      <div class="subtitle bold">
        {{ user.user_name }}
      </div>
      <div class="body">
        {{ user.grade }} - {{ user.capability_unit_l1 }}
      </div>
    </div>
  </div>

  <div class="nb-assets body3">
    {{ user.nb_docs }} {{ user.nb_docs> 1 ? 'assets': 'asset'}}
  </div>

  <div class="button-container">
    <button qm class="tertiary body2 view-details" (click)="viewDetails.emit({user: user})"><span class="qm-icon-eye-open"></span>View details</button>
    <div class="teams-contact" (click)="onContactClick($event)">
      <div class="teams-icon">
        <img src="/assets/teams-icon.svg" alt="teams"/>
      </div>
      <div class="body2 medium">Contact</div>
      <div class="arrow">
        <span class="qm-icon-chevron-right"></span>
      </div>
    </div>
  </div>

</div>
