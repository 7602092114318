import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserProfileService} from "../../services/api/user-profile/user-profile.service";
import {
  UserElementWithIndex,
  UserProfile,
  PostExpertFinderLoadMoreRequest
} from "../../services/api/user-profile/model/model";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationStrategy} from "@angular/common";

@Component({
  selector: 'app-expert-finder',
  templateUrl: './expert-finder.component.html',
  styleUrls: ['./expert-finder.component.scss']
})
export class ExpertFinderComponent implements OnInit {

  constructor(
    public userProfileService: UserProfileService,
    public route: ActivatedRoute,
    public router: Router,
    public location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
      this.backToPrincipalPage();
    });
  }

  resetButtonText?: string;
  placeholder: string = "Describe the expert you're looking for with an elaborate prompt ...";
  isPending: boolean = false;
  query_abstract: string = "";
  vector_abstract: number[] = [];
  search_id: number = -1;
  display_query: string = ""
  lastSearchReturnedError: boolean = false;
  messages: string[] = [];
  experts: UserProfile[] = [];
  rfpId?: number;
  displayResults: boolean = false;
  isLoadingMoreElements = false;
  expandedUser?: UserElementWithIndex;
  viewDetailsPosition = 0;
  @Output() openDetailsPage: EventEmitter<UserElementWithIndex> = new EventEmitter<UserElementWithIndex>();
  @Output() closeDetailsPage: EventEmitter<void> = new EventEmitter<void>();
  numberOfElementsToRetrieve: number = 6;


  ngOnInit(): void {
    const rfpId = this.route.snapshot.paramMap.get('id');
    if (rfpId !== null && rfpId !== undefined) {
      this.rfpId = +rfpId;
      this.getExpertsFromRfp();
    }
  }

  viewElementDetails(event: UserElementWithIndex) {
    this.viewDetailsPosition = window.scrollY;
    this.openDetailsPage.emit(event);
    this.expandedUser = event;
    window.scrollTo(0, 0);
  }

  backToPrincipalPage() {
    this.expandedUser = undefined;
    this.closeDetailsPage.emit();
    setTimeout(() => {
      window.scrollTo(0, this.viewDetailsPosition);
    }, 0);
  }

  postExpertFinder(body: string[]) {
    let message: string = body[0];
    let yearFilter: string = body[1];
    this.messages = this.messages.concat(message);
    this.isPending = true;
    this.userProfileService.postExpertFinder(this.messages).subscribe({
      next: (res) => {
        this.lastSearchReturnedError = false;
        this.experts = res.elements;
        this.display_query = "Here is what I found about " + message;
        this.query_abstract = res.query_abstract;
        this.vector_abstract = res.vector_abstract;
        this.search_id = res.search_id;
        this.isPending = false;
        this.resetButtonText = "New search";
        this.placeholder = "You can refine your search by adding new information";
        this.displayResults = true;
      },
      error: (err) => {
        this.isPending = false;
        this.resetSearch();
        this.lastSearchReturnedError = true;
      }
    });
  }

  resetSearch() {
    this.resetButtonText = undefined;
    this.messages = []
    this.query_abstract = "";
    this.display_query = "";
    this.search_id = -1;
    this.experts = [];
    this.placeholder = "Describe the slide you're looking for with an elaborate prompt..." +
      "";
    this.displayResults = false;
  }

  refineSearch() {
    this.display_query = "";
    this.resetButtonText = "New search";
  }

  getMoreExperts() {
    this.isLoadingMoreElements = true;
    let body: PostExpertFinderLoadMoreRequest = {
      query_abstract: this.query_abstract,
      vector_abstract: this.vector_abstract,
      year_filter: "",
      search_id: this.search_id
    }
    this.userProfileService.postExpertFinderLoadMore(body, this.numberOfElementsToRetrieve, this.experts.length).subscribe((res) => {
      if (this.experts) {
        this.experts = this.experts.concat(res.elements);
      }
      this.isLoadingMoreElements = false;
      this.displayResults = true;
    })
  }

  getExpertsFromRfp() {
    if (this.rfpId) {
      this.isPending = true;
      this.userProfileService.getExpertsFromRfp(this.rfpId, 6).subscribe({
        next: (res) => {
          this.experts = res.elements;
          this.displayResults = true;
          this.lastSearchReturnedError = false;
          this.query_abstract = res.query_abstract;
          this.vector_abstract = res.vector_abstract;
          this.isPending = false;
        },
        error: (err) => {
          console.error('Error fetching Experts from Rfp');
          this.isPending = false;
          this.lastSearchReturnedError = true;
        }
      })
    }
  }

  navigateToProfilePage() {
    this.router.navigate(['/profile']);
  }
}
