import {StoreState} from "../reducers";
import {createSelector} from "@ngrx/store";
import {SavedState} from "./saved.reducer";

const getSavedStore = (state: StoreState) => state.saved;

export const hasSavedDocs: (state: any) => boolean = createSelector(
  getSavedStore,
  (state: SavedState) => state.docs.length > 0
);

export const hasSavedSlides: (state: any) => boolean = createSelector(
  getSavedStore,
  (state: SavedState) => state.slides.length > 0
);

export const isSlideSaved: (state: any, search: {document_id: number, slide_number: number}) => boolean = createSelector(
  getSavedStore,
  (state: SavedState, search: {document_id: number, slide_number: number}) => {
    return state.slides.some(slide => slide.document_id === search.document_id && slide.slide_number === search.slide_number)
  }
);

export const isDocSaved: (state: any, document_id: number) => boolean = createSelector(
  getSavedStore,
  (state: SavedState, document_id: number) => state.docs.some(doc => doc.document_id === document_id)
);
