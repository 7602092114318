import {AssetElement} from "../../slide-finder/model/model";

export interface PostRfpResponse {
  id: number,
}

export interface RfpModel {
  id: number,
  user_oid: string,
  name: string,
  summary?: string,
  creation_date: string,
  abstract?: string
  analysis_status: RfpAnalysisStatus,
}

export type RfpAnalysisStatus = "EMPTY" | "TO_DO" | "IN_PROGRESS" | "UP_TO_DATE" | "OUTDATED" | "ERROR"

export interface GetRfpsResponse {
  list_rfps: RfpModel[]
}

export interface GetRfpResponse {
  rfp: RfpModel,
  documents: RfpDocument[]
}

export interface RfpDocument {
  id: number,
  file_name: string,
  file_type: string,
  file_size_in_bytes: number
}

export interface PostRfpDocumentsResponse {
  documents: RfpDocument[]
}

export interface GetRfpTextDocumentContentResponse {
  text_title: string,
  text_content: string
}

export interface GetRfpAssetsResponse {
  elements: AssetElement[];
  query_abstract: string;
  vector_abstract: number[];
  search_id: number;
  error: boolean;
}

export interface SourceDocument {
  source_name: string,
  position: number

}
export interface PostRfpRagResponse {
  answer: string,
  sources: SourceDocument[]

}

export interface PostRfpQuestionRequest{
  text: string
  rfp_id: number
}

export interface RfpMessage {
  text: string;
  sources?: SourceDocument[];
  isUser: boolean;
}

export interface RfpConversation {
  messages: RfpMessage[]
}

export interface RfpConversationDb {
  text: string
  is_user: boolean
  order: number
  rfp_id: number
}

export interface GetRfpConversationResponse {
  messages: RfpConversationDb[];
}
