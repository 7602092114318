import * as moment from "moment/moment";
import {blobToBase64} from "./blobToBase64";

export class Utils {

  getFileNameWithoutExtension(fileName: string | undefined): string {
    if (fileName != undefined) {
      return fileName.replace('.pptx', '');
    }
    return "";
  }

  getYearFromDate(date: string | undefined): string {
    if (date == undefined) {
      return ""
    }
    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) {
      return "";
    }
    return parsedDate.getFullYear().toString();
  }

  formatStringDateToDDMMYYYY(date: string): string {
    return moment(date).format("DD-MM-YYYY");
  }

  public blobToBase64(blob: Blob, callbackOnLoaded: () => void) {
    return blobToBase64(blob, callbackOnLoaded);
  }
}
