import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssetElement} from "../../services/api/slide-finder/model/model";
import {Thumbnails} from "../../utils/thumbnails";
import {HttpClient} from "@angular/common/http";
import {Utils} from "../../utils/utils";

import {AssetElementLog} from "../../services/api/admin/model/model";


export interface ContactPerson {
  displayName: string,
  jobTitle: string,
  email: string
}

@Component({
  selector: 'app-asset-element-details',
  templateUrl: './asset-element-details.component.html',
  styleUrls: ['./asset-element-details.component.scss']
})
export class AssetElementDetailsComponent implements OnInit {
  currentSlideIndex: number

  @Input() assetElement: AssetElement;
  @Input() backButtonText: string;
  @Input() displayAfterAndBackButtons: boolean;
  @Output() back: EventEmitter<void> = new EventEmitter<void>();
  @Output() logOpenDocument: EventEmitter<AssetElementLog> = new EventEmitter<AssetElementLog>();
  utils: Utils = new Utils();

  constructor(public thumbnails: Thumbnails, public http: HttpClient) {
  }

  emptyString: string = "";
  filterNA: string = 'N/A';

  opportunityLead: ContactPerson = {
    displayName: "",
    jobTitle: "",
    email: "",
  }

  contactPersons: ContactPerson[] = []

  ngOnInit(): void {
    if (this.assetElement.opportunity_lead) {
      const url = "https://graph.microsoft.com/v1.0/users?$filter=mail eq '" + this.assetElement.opportunity_lead + "'";
      this.http.get<any>(url).subscribe(res => {
        if (res.value && res.value[0]) {
          this.opportunityLead.displayName = res.value[0].displayName;
          this.opportunityLead.jobTitle = res.value[0].jobTitle;
        }
      });
    }
    if (this.assetElement.contact_persons) {
      for(let contactPerson of this.assetElement.contact_persons){
        if(contactPerson == this.assetElement.opportunity_lead){
          continue;
        }
        const url = "https://graph.microsoft.com/v1.0/users?$filter=mail eq '" + contactPerson + "'";
        this.http.get<any>(url).subscribe(res => {
          if (res.value && res.value[0]) {
            const newContact = {
              displayName : res.value[0].displayName,
              jobTitle : res.value[0].jobTitle,
              email: contactPerson
            }
            this.contactPersons.push(newContact);
          }
        });
      }
    }
    this.thumbnails.fetchIkhProposalSlideThumbnail(this.assetElement.unique_id, this.assetElement.slide_index, true);
    this.currentSlideIndex = this.assetElement.slide_index;
    this.loadNextAndPreviousAsset();
  }

  getPreviousThumbnail(): void {
    if (this.currentSlideIndex !== 0) {
      this.currentSlideIndex -= 1;
      this.loadNextAndPreviousAsset();
    }
  }

  getNextThumbnail(): void {
    if (this.currentSlideIndex !== this.assetElement.slides_urls.length - 1) {
      this.currentSlideIndex += 1
      this.loadNextAndPreviousAsset();
    }
  }

  contactTeams(email: string) {
    window.open('msteams://teams.microsoft.com/l/chat/0/0?users=' + email, '_blank');
  }

  openAsset() {
    const url = this.assetElement.slides_urls[this.currentSlideIndex];
    window.open(url, '_blank');
    this.logOpenDocument.emit({
      sharepoint_uri: this.assetElement.sharepoint_uri,
      slide_index: this.currentSlideIndex,
    })
  }

  copyAsset() {
    navigator.clipboard.writeText(this.assetElement.slides_urls[this.currentSlideIndex]);
  }

  loadNextAndPreviousAsset() {
    if (this.currentSlideIndex !== 0) {
      this.thumbnails.fetchIkhProposalSlideThumbnail(this.assetElement.unique_id, this.currentSlideIndex - 1, true)
    }
    if (this.currentSlideIndex !== this.assetElement.slides_urls.length - 1) {
      this.thumbnails.fetchIkhProposalSlideThumbnail(this.assetElement.unique_id, this.currentSlideIndex + 1, true)
    }
  }

  getSecStatusDescriptionAndColor(): { description: string, color: string } | null {
    if (this.assetElement.sec_status === "SEC 0") {
      return {
        description: "Public:\n" +
          "-> Internal and External use free, also for mass communication\n" +
          "-> For Project Asset Docs free targeted use only, no mass communication",
        color: "green"
      }
    }
    if (this.assetElement.sec_status === "SEC 1") {
      return {
        description: "Company Confidential:\n" +
          "-> Internally free for reading and sharing within CG\n" +
          "-> Externally use according to set permission status only",
        color: "orange"
      }
    }
    if (this.assetElement.sec_status === "SEC 2") {
      return {
        description: "Restricted Doc:\n" +
          "-> Internally can only be shared on a “need to know” basis\n" +
          "-> External use of an entire SEC 2 document is strictly prohibited",
        color: "red"
      }
    }
    return null;
  }

  getContacts(): ContactPerson[] {
    return this.contactPersons.filter(element => element.displayName != "");
  }
}
