import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() headerTitle: string;
  @Input() headerDescription: string;
  @Input() headerBackgroundColor: string;
  @Input() goBackButtonAvailable = false;
  @Input() minimized = false;
  @Output() navigate: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  isLargeScreen() {
    return window.innerWidth > 1450;
  }
}
