import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SavedService} from "../../services/api/saved/saved.service";
import {AssetElement, AssetElementWithIndex} from "../../services/api/slide-finder/model/model";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-saved-assets',
  templateUrl: './saved-assets.component.html',
  styleUrls: ['./saved-assets.component.scss']
})
export class SavedAssetsComponent implements OnInit {

  constructor(
    public savedService: SavedService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  selectedTab: 'Docs' | 'Slide' = 'Docs';
  fetching = false;
  assetElements: AssetElement[] = [];
  viewDetailsPosition: number;
  expandedAssetElement: AssetElement | undefined;
  @Output() openDetailsPage: EventEmitter<AssetElementWithIndex> = new EventEmitter<AssetElementWithIndex>();
  @Output() closeDetailsPage: EventEmitter<void> = new EventEmitter<void>();


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params['tab'] && (params['tab'] === 'Docs' || params['tab'] === 'Slide')){
        this.selectTab(params['tab']);
      } else {
        this.selectTab('Docs');
      }
    });
  }

  clickOnSelectTab(tab: 'Docs' | 'Slide'){
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {tab},
      queryParamsHandling: 'merge'
    });
  }

  selectTab(tab: 'Docs' | 'Slide'){
    this.selectedTab = tab;
    if(tab === 'Docs'){
      this.fetchSavedDocs();
    }else{
      this.fetchSavedSlides();
    }
  }

  fetchSavedDocs(){
    this.fetching = true;
    this.assetElements = [];
    this.savedService.getSavedIkhDocs().subscribe(res => {
      this.fetching = false;
      this.assetElements = res.assets;
    })
  }

  fetchSavedSlides(){
    this.fetching = true;
    this.assetElements = [];
    this.savedService.getSavedIkhSlides().subscribe(res => {
      this.fetching = false;
      this.assetElements = res.assets;
    })
  }

  setViewDetails(asset: AssetElement) {
    this.viewDetailsPosition = window.scrollY;
    this.expandedAssetElement = asset;
    this.openDetailsPage.emit({assetElement: asset, index: asset.slide_index});
  }

  closeViewDetails() {
    this.expandedAssetElement = undefined;
    this.closeDetailsPage.emit();
    setTimeout(() => {
      window.scrollTo(0, this.viewDetailsPosition);
    }, 0);
  }
}
