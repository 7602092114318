import {userReducer, UserState} from "./user/user.reducer";
import {rfpAssistantReducer, RfpState} from "./rfp-assistant/rfp-assistant.reducer";
import {usersReducer, UsersState} from "./users/users.reducer";
import {savedReducer, SavedState} from "./saved/saved.reducer";

export interface StoreState {
  user: UserState,
  rfpAssistant: RfpState,
  users: UsersState,
  saved: SavedState
}

export const reducers = {
  user: userReducer,
  rfpAssistant: rfpAssistantReducer,
  users: usersReducer,
  saved: savedReducer
};
