<div *ngIf="!expandedAssetElement" class="main-container">

  <div class="top-profile-container">
    <h1 class="title">
      YOUR PROFILE
    </h1>
    <div class="horizontal-block">
      <div class="user-info">
        <div *ngIf="picture" class="picture-container">
          <img [src]="picture" alt="profile picture">
        </div>
        <div *ngIf="!picture" class="profile-picture">
          <span style="font-size: 70px; color: #FFFFFF; margin: 0;" class="qm-icon-user-profile-circle"></span>
        </div>
        <div class="info-container">
          <h3 class="name medium white-text" style="margin: 0;">
            {{ userInfo && userInfo.displayName }}
          </h3>
          <div class="email white-text">
            {{ userInfo && userInfo.email }}
          </div>
          <div class="email white-text">
            {{ user && user.grade }}
          </div>
        </div>
      </div>
      <div class="personal-info-container">
        <div class="block-header">
          <div class="bold subtitle white-text">
            Personal information
          </div>
        </div>
        <div class="personal-info-content">
          <div class="content-block">
            <div class="body2 white-text">
              Capability Unit
            </div>
            <div class="body2 white-text">
              {{user && user.capability_unit}} {{ user && user.capability_unit_l1 }}
            </div>
          </div>
          <div class="content-block">
            <div class="body2 white-text">
              City
            </div>
            <div class="body2 white-text">
              {{user && user.localisation}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="horizontal-block">
      <div>
        <div class="description">
          <div class="block-header">
            <div class="bold subtitle white-text">
              About me
            </div>
            <div class="edition">
              <button *ngIf="!editingDescription" qm class="secondary reversed medium" icon="edit-03"
                      (click)="editingDescription=true;">
                <div *ngIf="!editingDescription" class="body2 medium white-text">
                  Edit
                </div>
              </button>
            </div>
          </div>
          <div class="description-content">
            <div *ngIf="!editingDescription && user && user.description.length === 0" class="body"
                 style="color: var(--grey-400-color)"> Provide information about yourself to boost your profile in the expert finder.
            </div>
            <div *ngIf="user && !editingDescription" class="body white-text"> {{ user.description }}</div>
          </div>
        </div>
        <div *ngIf="editingDescription" class="input-description">

          <textarea class="input-message" style="background-color: transparent; color: #FFFFFF; height:166px;"
                    [(ngModel)]="user.description"
                    [maxlength]="300"
                    qm
          ></textarea>
            <div class="caption white-text character-count">
              {{ user.description ? user.description.length : 0 }}/{{ descriptionMaxLength }}
            </div>

            <button class="secondary confirm-button-class"
                    (click)="patchUserProfile();editingDescription=false;" qm>Confirm
            </button>
        </div>
      </div>

      <div class="expertise">
        <div class="block-header">
          <div class="bold subtitle white-text">
            Expertise
          </div>
          <div class="edition">
            <button *ngIf="!editing_expertise" qm class="secondary reversed medium" icon="edit-03"
                    (click)="editing_expertise=true;">
              <div *ngIf="!editing_expertise" class="body2 medium white-text">
                Edit
              </div>
            </button>
          </div>
        </div>

        <div *ngIf="!editing_expertise" class="expertise-content">
          <div *ngIf="user && !user.expertises.length && !editing_expertise" class="body"
               style="color: var(--grey-400-color)"> No expertise provided.
          </div>
          <div *ngIf="user && !editing_expertise" class="tag-filter">
            <qmtagfilter *ngFor="let tag of list_expertises" class="small">{{ tag }}</qmtagfilter>
          </div>
        </div>
        <div *ngIf="editing_expertise" class="input-expertise">
          <qmtaglist class="transparent-white"
                     [allowAny]="list_expertises.length < 4"
                     [allowAnyText]="allowAnyText"
                     [itemTemplate]="tagListExpertisesTypeTemplate"
                     [tagToString]="getTagListToStringFunction()"
                     [objectLists]="list_expertises"
                     (tagListChange)="setTagsLists($event)"
                     placeholder="Add your expertises">
            <ng-template #tagListExpertisesTypeTemplate let-element>
              {{ element }}
            </ng-template>
          </qmtaglist>
          <div class="expertise-count">
            You can add up to 4 expertises
          </div>
          <div>
            <button class="secondary confirm-button-class" [disabled]="list_expertises.length > 4"
                    (click)="patchUserProfile();editing_expertise=false;" qm>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom-profile-container">
    <h3 class="medium" style="color: var(--text-grey-extreme); margin: 0">
      Assets you contributed to
    </h3>

    <div class="shared-asset-lists">
      <div class="asset-container new">
        <div class="body2 title-container">
          Accelerate decision-making and optimize collaboration by sharing documents with team.
        </div>
        <button class="large" (click)="navigateToShareAssets()" qm>
          Share documents
        </button>
      </div>
      <div *ngFor="let element of PendingSharedAssets.assets, let i=index"
           class="asset-container pending">

        <div class="alert-container">
          <qminfo [text]="'Asset shared ingestion in progress...'"></qminfo>
        </div>
        <div class="info-container">
          <div class="asset-name">
            {{ utils.getFileNameWithoutExtension(element.name) }}
          </div>
          <div class="asset-date">
            {{ element.account }}
          </div>
          <div class="asset-metadata-holder">
            <span *ngIf="element && element.industry" class="metadata">{{ element.industry }}</span>
            <span *ngIf="element && element.capability_unit"
                  class="metadata">{{ element.capability_unit }}</span>
            <span *ngIf="element && element.capability_unit_l1"
                  class="metadata">{{ element.capability_unit_l1 }}</span>
          </div>
        </div>
      </div>
      <app-asset-element-snippet
              class="asset-container shared"
              *ngFor="let element of CompletedSharedAssets.assets, let i=index"
              [assetElement]=element
              [mode]="'Asset Retriever'"
              (viewDetails)="viewElementDetails({assetElement: element, index:i})"
      >
      </app-asset-element-snippet>

    </div>
  </div>

</div>


<app-asset-element-details
        *ngIf="expandedAssetElement"
        [isDoc]="true"
        [assetElement]="expandedAssetElement.assetElement"
        [displayAfterAndBackButtons]="true"
        [backButtonText]="'Back to your profile'"
        (back)="backToPrincipalPage()"
>
</app-asset-element-details>
