<div *ngIf="pending" class="container">
  <app-asset-element-snippet
    *ngFor="let i of [0,1,2]"
    [assetElement]=null
    [mode]=mode>
  </app-asset-element-snippet>
</div>
<div *ngIf="!pending" class="container" [ngClass]="displayTwoColumns ? 'two-columns' : ''">
  <app-asset-element-snippet
    *ngFor="let element of assetElements, let i=index"
    [ngClass]="displayTwoColumns ? 'two-columns' : ''"
    [assetElement]=element
    [mode]=mode
    (viewDetails)="viewDetails.emit({assetElement: element, index: i})"
    (logOpenDocument)="logOpenDocument.emit($event)"
  >
  </app-asset-element-snippet>
</div>
<ng-container *ngIf="displayLoadingMoreElements">
<div class="more-elements">
  <div *ngIf="isLoadingMoreElements" class="spinner">
    <qmspinner *ngIf="isLoadingMoreElements"></qmspinner>
  </div>
  <span (click)="getMoreElements()"
        *ngIf="assetElements && !isLoadingMoreElements && !pending && searchMoreElements">Search more elements</span>
</div>
<div class="upload-help-container" *ngIf="assetElements && !isLoadingMoreElements && !pending  && displayUpload">
   <div *ngIf="!searchMoreElements" class="upload-help-text body2 medium">
    We couldn't find more results that match your request. You may help us to expand our content database
  </div>
  <div  *ngIf="searchMoreElements" class="upload-help-text body2 medium">
    Haven't found the ideal content? You may help us to expand our content database
  </div>
  <button
    qm
    class="secondary"
    (click)="uploadDocNavigation.emit()"
  >
    Upload document
  </button>
</div>
</ng-container>
