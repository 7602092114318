import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of} from "rxjs";
import {UserProfileService} from "../../services/api/user-profile/user-profile.service";
import {UserProfile} from "../../services/api/user-profile/model/model";

@Component({
  selector: 'app-manage-contributors',
  templateUrl: './manage-contributors.component.html',
  styleUrls: ['./manage-contributors.component.scss']
})
export class ManageContributorsComponent implements OnInit, OnChanges {
  users: UserProfile[];
  addingLead = false;
  addingContributor = false
  leadUser: UserProfile[] = [];
  @Input() contributorUsers: UserProfile[] = [];
  @Input() opportunityLead?: string;
  @Input() isProjectAsset: boolean = true;
  @Output() sendLead: EventEmitter<UserProfile> = new EventEmitter<UserProfile>();
  @Output() sendContributors: EventEmitter<UserProfile[]> = new EventEmitter<UserProfile[]>();

  constructor(
    public userProfileService: UserProfileService
  ) {
  }

  emptyUser: UserProfile = {
    user_oid: "",
    user_name: "",
    email: "",
    grade: "",
    localisation: "",
    capability_unit: "",
    capability_unit_l1: "",
    description: "",
    expertises: [],
  };

  ngOnInit(): void {
    this.userProfileService.getUsers().subscribe(res => {
      this.users = res.user_profiles
      this.retrieveUserLead();
    });
  }

  ngOnChanges() {
    this.retrieveUserLead();
  };

  retrieveUserLead() {
    if (this.opportunityLead && this.users) {
      const leadUser = this.users.find(user => user.user_name === this.opportunityLead);
      if (leadUser) {
        this.leadUser = [leadUser];
        this.sendLead.emit(this.leadUser[0]);
      }
    }
  }


  getTagListToStringFunction() {
    return (user: UserProfile) => user.user_name;
  }

  getTagToIdFunction() {
    return (user: UserProfile) => user.email;
  }

  getPossibleTagObjectFunction: (value: string) => Observable<UserProfile[]> = (search: string) => {
    return of(this.users.map(u => ({
      ...u,
      displayValue: u.user_name
    })).filter(u => (u.user_name).toLowerCase().indexOf(search.toLowerCase()) > -1));
  };

  assignRole(users: UserProfile[], role: string): void {
    // Add logic to assign the selected user to opportunity lead
    if (role === "OpportunityLead") {
      this.leadUser = users;
    } else if (role === "Contributors") {
      this.contributorUsers = users;
    }
  }

  changeLead() {
    this.addingLead = false;
    if (this.leadUser.length > 0) {
      this.sendLead.emit(this.leadUser[0]);
    } else {
      this.sendLead.emit(this.emptyUser);
    }

  }

  changeContributors() {
    this.addingContributor = false;
    this.sendContributors.emit(this.contributorUsers);
  }

}
