import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AssetElement} from "../../services/api/slide-finder/model/model";
import {HttpClient} from "@angular/common/http";
import {Utils} from "../../utils/utils";
import {Thumbnails} from "../../utils/thumbnails";
import {AssetElementLog} from "../../services/api/admin/model/model";

export type Mode = 'Asset Retriever' | 'Slide Finder';

@Component({
  selector: 'app-asset-element-snippet',
  templateUrl: './asset-element-snippet.component.html',
  styleUrls: ['./asset-element-snippet.component.scss']
})
export class AssetElementSnippetComponent implements OnInit {

  @Input() assetElement: AssetElement | null;
  @Input() mode: Mode;
  @Output() viewDetails: EventEmitter<string> = new EventEmitter<string>();
  @Output() logOpenDocument: EventEmitter<AssetElementLog> = new EventEmitter<AssetElementLog>();
  utils: Utils = new Utils();
  errorThumbnails = false;
  filterNA: string = "N/A"


  constructor(public http: HttpClient, public thumbnails: Thumbnails) {
  }

  ngOnInit(): void {
    if (this.assetElement) {
      if (this.assetElement.ingestion_status === "SUCCESS") {
        this.thumbnails.fetchIkhProposalSlideThumbnail(this.assetElement.unique_id, this.assetElement.slide_index, false)
      } else {
        this.errorThumbnails = true;
      }
    }
  }

  openDocument(): void {
    if (this.assetElement) {
      const url = this.assetElement.slides_urls[this.assetElement.slide_index];
      window.open(url, '_blank');
      this.logOpenDocument.emit({
        sharepoint_uri: this.assetElement.sharepoint_uri,
        slide_index: this.assetElement.slide_index
      })
    }
  }
}
