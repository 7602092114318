import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AssetElement, AssetElementWithIndex} from "../../services/api/slide-finder/model/model";
import {Mode} from "../asset-element-snippet/asset-element-snippet.component";
import {AssetElementLog} from "../../services/api/admin/model/model";
import {elementAt} from "rxjs";

@Component({
  selector: 'app-asset-elements-container',
  templateUrl: './asset-elements-container.component.html',
  styleUrls: ['./asset-elements-container.component.scss']
})
export class AssetElementsContainerComponent implements OnInit {

  @Input() assetElements: AssetElement[];
  @Input() mode: Mode;
  @Input() pending: boolean = false;
  @Input() isLoadingMoreElements: boolean = false;
  @Input() displayLoadingMoreElements: boolean = true;
  @Input() displayUpload: boolean = true;
  @Input() searchMoreElements: boolean = false;
  @Input() yearFilter:string = "";
  @Output() getNext: EventEmitter<void> = new EventEmitter<void>();
  @Output() viewDetails: EventEmitter<AssetElementWithIndex> = new EventEmitter<AssetElementWithIndex>();
  @Output() logOpenDocument: EventEmitter<AssetElementLog> = new EventEmitter<AssetElementLog>();
  @Output() uploadDocNavigation: EventEmitter<void> = new EventEmitter<void>;
  @Input() displayTwoColumns: boolean = false;


  constructor() {
  }

  ngOnInit(): void {
  }

  getMoreElements() {
    this.getNext.emit()
  }
}
