import {createReducer, on} from "@ngrx/store";
import {UserProfile} from "../../services/api/user-profile/model/model";
import {fetchUsers, setUsers} from "./users.actions";

export interface UsersState {
  users: {[mail: string]: UserProfile };
}

const initialState: UsersState = {
  users : {}
};

export const usersReducer = createReducer<UsersState>(
  initialState,
  on(fetchUsers, (state) => ({...state})),
  on(setUsers, (state, {users}) => {
    const newUsers : {[mail: string]: UserProfile } = {};
    for (const user of users) {
      newUsers[user.email] = user;
    }
    return {...state, users: newUsers}
  }),
);
