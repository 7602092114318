import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from "@azure/msal-angular";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {NewAssetPageComponent} from "./pages/new-asset-page/new-asset-page.component";
import {AuthGuard} from "./utils/auth.guard";
import {AdminPageComponent} from "./pages/admin-page/admin-page.component";
import {SlideFinderPageComponent} from "./pages/slide-finder-page/slide-finder-page.component";
import {DocFinderPageComponent} from "./pages/doc-finder-page/doc-finder-page.component";
import {AdminGuard} from "./utils/admin.guard";
import {RfpAssistantComponent} from "./pages/rfp-assistant/rfp-assistant.component";
import {RfpDetailsComponent} from "./pages/rfp-assistant/rfp-details/rfp-details.component";
import {ProfilePageComponent} from "./pages/profile-page/profile-page.component";
import {SecureChatGptComponent} from "./pages/secure-chatgpt/secure-chatgpt.component";


const routes: Routes = [
  {
    path: 'secure-chatgpt',
    canActivate: [MsalGuard, AuthGuard],
    component: SecureChatGptComponent
  },
  {
    path: 'upload-docs',
    canActivate: [MsalGuard, AuthGuard],
    component: NewAssetPageComponent
  },
    {
    path: 'upload-docs/:opportunity_id',
    canActivate: [MsalGuard, AuthGuard],
    component: NewAssetPageComponent
  },
  {
    path: 'admin',
    canActivate: [MsalGuard, AdminGuard],
    component: AdminPageComponent
  },
  {
    path: 'slide-finder',
    canActivate: [MsalGuard, AuthGuard],
    component: SlideFinderPageComponent
  },
  {
    path: 'doc-finder',
    canActivate: [MsalGuard, AuthGuard],
    component: DocFinderPageComponent
  },
  {
    path: 'doc-finder/:id',
    canActivate: [MsalGuard, AuthGuard],
    component: DocFinderPageComponent
  },
  {
    path: 'rfp-assistant',
    canActivate: [MsalGuard, AuthGuard],
    component: RfpAssistantComponent
  },
  {
    path: 'rfp-assistant/:id',
    canActivate: [MsalGuard, AuthGuard],
    component: RfpDetailsComponent
  },
  {
    path: 'profile',
    canActivate: [MsalGuard, AuthGuard],
    component: ProfilePageComponent
  },
  {
    path: '',
    component: HomePageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
