<div class="top-bar">

</div>
<div class="admin-page">
  Admin Page
</div>
<div class="files-container">
  <div>
    <div>
      {{ proposals ? "Number of files : " + proposals.length : "Loading ikh_proposals_document table..." }}
    </div>
  </div>
  <div>
    <div *ngIf="proposals">
      DB Version : {{ this.DBVersion }}
    </div>
  </div>
  <div class="buttons-container">
    <button qm [disabled]="!ikhProposalsFailureUploads" (click)="showingFailureUploads = !showingFailureUploads">
      {{ showingFailureUploads ? "Show IKH documents" : "Show failed uploads" }}
    </button>
    <button qm [disabled]="downloadingSearchLogs" (click)="downloadAssetRetrieverSlideFinderLogs()">
      Asset Retriever / Slide Finder Logs
    </button>
    <br/>
    <br/>
    <button qm [disabled]="downloadingUsage" (click)="downloadUsageLogs()">
      Usage Logs
    </button>
    <button qm [disabled]="downloadingSlideFinder" (click)="downloadErrorLogs()">
      Error Logs
    </button>
    <br/>
  </div>
</div>
<div *ngIf="!showingFailureUploads && proposals" class="global-statistics">
  <div [class]="'ingestion-ok'">
    SUCCESS<span> - {{ getNbDocumentByStatus('SUCCESS') }}</span>
  </div>
  <div [class]="'ingestion-ko'">
    FAILURE<span> - {{ getNbDocumentByStatus('FAILURE') }}</span>
    <br/>
    <div class="restart-ingestion">
      <span *ngIf="!pendingRestartIngestion['FAILURE']"
            (click)="resetIngestionFailures('FAILURE')"
            class="qm-icon-arrow-refresh-01"></span>
      <qmspinner *ngIf="pendingRestartIngestion['FAILURE']"></qmspinner>
    </div>
  </div>
  <div [class]="'ingestion-ko'">
    FILE_UNAVAILABLE<span> - {{ getNbDocumentByStatus('FILE_UNAVAILABLE') }}</span>
    <br/>
    <div class="restart-ingestion">
      <span *ngIf="!pendingRestartIngestion['FILE_UNAVAILABLE']"
            (click)="resetIngestionFailures('FILE_UNAVAILABLE')"
            class="qm-icon-arrow-refresh-01"></span>
      <qmspinner *ngIf="pendingRestartIngestion['FILE_UNAVAILABLE']"></qmspinner>
    </div>
  </div>
  <div [class]="'ingestion-ko'">
    TODO<span> - {{ getNbDocumentByStatus('TODO') }}</span>
  </div>
</div>
<div *ngIf="!showingFailureUploads" class="ikh-proposals-table">
  <div class="table-row table-head">
    <div>File name</div>
    <div>
      Ingestion
    </div>
    <div>IKH</div>
    <div>Delete</div>
  </div>
  <div *ngFor="let proposal of proposals" class="table-row">
    <div class="proposal-name">
      {{ proposal.name }}
      <br/>
      <span>{{ proposal.unique_id }}</span>
    </div>
    <div [class]="proposal.ingestion_status === 'SUCCESS' ? 'ingestion-ok' : 'ingestion-ko'">
      {{ proposal.ingestion_status }}
    </div>
    <div>
      <a [href]="'https://capgemini.sharepoint.com' + proposal.sharepoint_uri">Link</a>
    </div>
    <div>
      <button qm icon="trash-01" (click)="deleteIkhProposal(proposal.id)"></button>
    </div>
  </div>
</div>
<div *ngIf="showingFailureUploads" class="ikh-proposals-table">
  <div class="table-row table-head">
    <div>File name</div>
    <div>Uploader Email</div>
    <div>Correlation ID</div>
    <div>Metadata</div>
    <div>Asset type</div>
    <div>Failure time</div>
    <div>Error trace</div>
    <div>Processed ?</div>
    <div>Retry upload</div>
    <div>Delete</div>
  </div>
  <div *ngFor="let failure of ikhProposalsFailureUploads" class="table-row">
    <div class="scrollable">
      {{ failure.filename }}
    </div>
    <div class="scrollable">{{ failure.user_email }}</div>
    <div class="scrollable">{{ failure.correlation_id }}</div>
    <div class="scrollable">{{ failure.post_ikh_proposal_metadata_json }}</div>
    <div class="scrollable">{{ failure.asset_type }}</div>
    <div class="scrollable">{{ utils.formatStringDateToDDMMYYYY(failure.failure_datetime) }}</div>
    <div class="scrollable">{{ failure.error_trace }}</div>
    <div [class]="'scrollable' + failure.processed ? 'ingestion-ok' : 'ingestion-ko'">{{ failure.processed }}</div>
    <div>
      <span (click)="setIkhProposalsFailureUploadToProcessed(failure.id)"
            class="qm-icon-arrow-refresh-01">
      </span>
    </div>
    <div>
      <button (click)="deleteIkhProposalsFailureUpload(failure.id)"
              icon="trash-01"
              qm>
      </button>
    </div>
  </div>
</div>
