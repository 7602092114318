import {Observable} from "rxjs";

export function blobToBase64(blob: Blob, callbackOnLoaded: () => void): Observable<string> {

  return new Observable<string>(observer => {
    const reader = new FileReader();
    reader.onerror = observer.error;
    reader.onabort = observer.error;
    reader.onload = () => {
      observer.next(reader.result as string);
      callbackOnLoaded();
    };
    reader.readAsDataURL(blob);

    return {
      unsubscribe: reader.abort
    };
  });
}
