import {UserEffects} from "./user/user.effects";
import {RfpAssistantEffects} from "./rfp-assistant/rfp-assistant.effects";
import {UsersEffects} from "./users/users.effects";
import {SavedEffects} from "./saved/saved.effects";

export const effects = [
  UserEffects,
  RfpAssistantEffects,
  UsersEffects,
  SavedEffects
];
