import {createAction, props} from "@ngrx/store";
import {UserProfile} from "../../services/api/user-profile/model/model";
import {SavedIkhDoc, SavedIkhSlide} from "../../services/api/saved/model/model";

export const fetchSaved = createAction(
  '[Saved] fetch saved'
);

export const setSaved = createAction(
  '[Saved] set saved',
  props<{ slides: SavedIkhSlide[], docs: SavedIkhDoc[] }>()
);

export const saveSlide = createAction(
  '[Saved] save slide',
  props<{ document_id: number, slide_number: number }>()
);

export const unsaveSlide = createAction(
  '[Saved] unsave slide',
  props<{ document_id: number, slide_number: number }>()
);

export const saveDoc = createAction(
  '[Saved] save doc',
  props<{ document_id: number }>()
);

export const unsaveDoc = createAction(
  '[Saved] unsave doc',
  props<{ document_id: number }>()
);


export const createFetchSavedAction = () => fetchSaved();
export const createSetSavedAction = (slides: SavedIkhSlide[], docs: SavedIkhDoc[]) => setSaved({slides, docs});
export const createSaveSlideAction = (document_id: number, slide_number: number) => saveSlide({document_id, slide_number});
export const createSaveDocAction = (document_id: number) => saveDoc({document_id});
export const createUnsaveSlideAction = (document_id: number, slide_number: number) => unsaveSlide({document_id, slide_number});
export const createUnsaveDocAction = (document_id: number) => unsaveDoc({document_id});
