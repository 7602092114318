import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of} from "rxjs";
import {UserProfile} from "../../services/api/user-profile/model/model";
import {Store} from "@ngrx/store";
import {selectUsers} from "../../store/users/users.selectors";

@Component({
  selector: 'app-manage-contributors',
  templateUrl: './manage-contributors.component.html',
  styleUrls: ['./manage-contributors.component.scss']
})
export class ManageContributorsComponent implements OnInit, OnChanges {
  users: UserProfile[];
  addingLead = false;
  addingContributor = false
  leadUser: UserProfile[] = [];
  @Input() listContributorsEmail: string[] = []
  @Input() contributorUsers: UserProfile[] = [];
  @Input() opportunityLead?: string;
  @Input() showOpportunityLead: boolean = true;
  @Input() showContributorsBadge = true;
  @Input() leadTitle = "";
  @Input() contributorTitle = "";
  @Output() isComponentOpen: EventEmitter<boolean>= new EventEmitter<boolean>();
  @Output() sendLead: EventEmitter<UserProfile> = new EventEmitter<UserProfile>();
  @Output() sendContributors: EventEmitter<UserProfile[]> = new EventEmitter<UserProfile[]>();

  constructor(
    public store: Store
  ) {
  }

  emptyUser: UserProfile = {
    user_oid: "",
    user_name: "",
    email: "",
    grade: "",
    localisation: "",
    capability_unit: "",
    capability_unit_l1: "",
    description: "",
    expertises: [],
    nb_docs: 0
  };

  ngOnInit(): void {
    this.store.select(selectUsers).subscribe(res => {
      this.users = res;
      this.retrieveUserLead();
      this.retrieveContributors();
    });
  }

  ngOnChanges(changes:SimpleChanges) {
    this.retrieveUserLead();
  };

  retrieveUserLead() {
    if (this.opportunityLead && this.users) {
      const leadUser = this.users.find(user => user.user_name === this.opportunityLead || user.email === this.opportunityLead);
      if (leadUser) {
        this.leadUser = [leadUser];
        this.sendLead.emit(this.leadUser[0]);
      }
    }
  }

  retrieveContributors() {
    if (this.listContributorsEmail) {
      this.contributorUsers = [];
      for (const contributor of this.listContributorsEmail) {
        const user = this.users.find(user => user.email === contributor)
        if (user) {
          this.contributorUsers.push(user);
        }
      }
    }
  }


  getTagListToStringFunction() {
    return (user: UserProfile) => user.user_name;
  }

  getTagToIdFunction() {
    return (user: UserProfile) => user.email;
  }

  getPossibleTagObjectFunction: (value: string) => Observable<UserProfile[]> = (search: string) => {
    return of(this.users.map(u => ({
      ...u,
      displayValue: u.user_name
    })).filter(u => {
      for(let sub_search of search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").split(/[ .]/)){
        if((u.user_name).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(sub_search) === -1){
          return false;
        }
      }
      return true;
    }).slice(0, 5));
  };

  assignRole(users: UserProfile[], role: string): void {
    // Add logic to assign the selected user to opportunity lead
    if (role === "OpportunityLead") {
      this.leadUser = users;
    } else if (role === "Contributors") {
      this.contributorUsers = users;
    }
  }

  changeLead() {
    this.addingLead = false;
    if (this.leadUser.length > 0) {
      this.sendLead.emit(this.leadUser[0]);
    } else {
      this.sendLead.emit(this.emptyUser);
    }

  }

  openComponent() {
    this.addingContributor = true;
    this.isComponentOpen.emit(true);
    this.retrieveContributors()
  }

  changeContributors() {
    this.addingContributor = false;
    this.sendContributors.emit(this.contributorUsers);
    this.isComponentOpen.emit(false);
  }

}
