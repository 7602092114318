<div [class]="'container ' + (assetElement ? 'not-pending' : 'pending')">
  <div class="miniature-container">
    <img alt="miniature"
         *ngIf="assetElement && thumbnails.getThumbnail(assetElement.unique_id, assetElement.slide_index, false)"
         [src]="thumbnails.getThumbnail(assetElement.unique_id, assetElement.slide_index, false)">
    <div
      *ngIf="(assetElement && !thumbnails.getThumbnail(assetElement.unique_id, assetElement.slide_index, false)) || errorThumbnails"
      class="gradient">
      <div *ngIf="errorThumbnails" class="error-thumbnails">
        <img src="/assets/camera-off.svg" style="height: 56px"/>
        <div class="caption medium" style="color: var(--text-grey-strong)">
          No preview available
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mode === 'Slide Finder'" class="gray-border">

  </div>
  <div *ngIf="mode === 'Asset Retriever'" class="asset-details">
    <div class="asset-name">
      {{ utils.getFileNameWithoutExtension(assetElement?.name) }}
    </div>
    <div class="body1 asset-date">

      <ng-container *ngIf="assetElement?.account">{{ assetElement?.account }}﹒</ng-container>
      {{ assetElement?.created_date | date:'yyyy' }}
    </div>
    <div class="asset-metadata-holder">
        <div class="keyword-tag" *ngFor="let keyword of assetElement?.keywords?.slice(0,5)">
          {{ keyword }}
        </div>
    </div>
  </div>
  <div class="details-buttons-container">
    <button qm *ngIf="assetElement"
            class="secondary"
            (click)="openDocument()">
      Open document
    </button>
    <button qm *ngIf="assetElement"
            class="tertiary"
            (click)="viewDetails.emit()">
      <span class="qm-icon-eye-open"></span>View details
    </button>
    <div *ngIf="!assetElement" class="pending-button"></div>
    <div *ngIf="!assetElement" class="pending-button"></div>
  </div>
</div>
