<div class="top-bar">
  <button class="tertiary" (click)="back.emit()" qm>
    <span class="qm-icon-arrow-left"></span>{{ backButtonText }}
  </button>
</div>
<div class="middle-container">
  <div class="details-container">
    <div class="title">
      {{ utils.getFileNameWithoutExtension(assetElement.name) }}
    </div>
    <div *ngIf="getSecStatusDescriptionAndColor()" class="sec-status-container">
      <div [class]="['sec-chip', 'sec-chip-color-' + getSecStatusDescriptionAndColor()?.color]"></div>
      <div class="sec-status">{{ assetElement.sec_status }}</div>
      <span class="qm-icon-information-circle-contained sec-tooltip-icon"></span>
      <pre class="sec-tooltip-description caption medium">{{ getSecStatusDescriptionAndColor()?.description }}</pre>
    </div>
    <div class="body1 asset-date">
      <span *ngIf="assetElement.account">
        {{ assetElement.account }}
        ﹒</span>
      {{ assetElement.created_date && assetElement.created_date.substring(0, 4) }}
    </div>
    <div class="asset-metadata-holder">
      <span *ngIf="assetElement && assetElement.capability_unit && assetElement.capability_unit !==filterNA"
            class="asset-tag">
        {{ assetElement.capability_unit }}﹒
      </span>
      <span *ngIf="assetElement && assetElement.capability_unit_l1 && assetElement.capability_unit_l1 !==filterNA"
            class="asset-tag">
        {{ assetElement.capability_unit_l1 }}﹒
      </span>
      <span *ngIf="assetElement && assetElement.industry && assetElement.industry !==filterNA"
            class="body1 asset-tag">
        {{ assetElement.industry }}
      </span>
    </div>
    <div class="asset-keywords-holder">
      <qmtagfilter class="medium keyword-tag"
                   *ngFor="let keyword of assetElement.keywords.slice(0,5)">{{ keyword }}
      </qmtagfilter>
    </div>
    <div class="contact">
      <div class="lead-container" *ngIf="opportunityLead.displayName!== emptyString">
        <div class="title">
          Contacts
        </div>
        <div class="contact-element"
             (click)="contactTeams(assetElement.opportunity_lead)"
             *ngIf="assetElement.opportunity_lead && opportunityLead.displayName!== emptyString">
          <div class="teams-icon">
            <img src="/assets/teams-icon.svg" alt="teams"/>
          </div>
          <div class="person">
            <div class="job">
              {{ opportunityLead.jobTitle }}
            </div>
            <div class="name">
              {{ opportunityLead.displayName }}
            </div>
          </div>
          <div class="arrow">
            <span class="qm-icon-arrow-right"></span>
          </div>
        </div>
      </div>
      <div class="contributors-container" *ngIf="getContacts().length > 0 ">
        <div class="title">
          Contributors
        </div>
        <div *ngIf="!addingContributor">
          <div class="contact-element"
               (click)="contactTeams(contactPerson.email)"
               *ngFor="let contactPerson of getContacts()">
            <div class="teams-icon">
              <img src="/assets/teams-icon.svg" alt="teams"/>
            </div>
            <div class="person">
              <div class="job">
                {{ contactPerson.jobTitle }}
              </div>
              <div class="name">
                {{ contactPerson.displayName }}
              </div>
            </div>
            <div class="arrow">
              <span class="qm-icon-arrow-right"></span>
            </div>
          </div>
        </div>
      </div>
      <app-manage-contributors
        *ngIf="!updatePending && canEditContributors()"
        [showOpportunityLead]="false"
        [showContributorsBadge]="false"
        [listContributorsEmail]="getContributorsMail()"
        (sendContributors)="addContributors($event)"
        (isComponentOpen)="addingContributor=$event"
      >
      </app-manage-contributors>
      <button qm class="tertiary add-button"
              (click)="addMeAsAContributor()"
              *ngIf="!addingContributor && !updatePending && !isMyProfileContributor() && canEditContributors()">
        I contributed to this doc
      </button>
      <qmspinner *ngIf="updatePending" class="spinner"></qmspinner>
      <qminfo
        [alert]="true"
        *ngIf="errorUpdating"
        [text]="'Error when updating document contributors'"
      ></qminfo>
    </div>
  </div>

  <div class="slide-viewer">
    <div class="save-bar body2 regular">
      <button qm class="tertiary copy-link" (click)="saveAsset()" *ngIf="!saved">
        Save {{isDoc ? "Doc": "Slide"}}
        <img style="margin-top: -2px; margin-left: -2px" class="svg-saved" src="/assets/favoris-on.svg"/>
      </button>
      <div *ngIf="saved" class="saved-container"
           (click)="unsaveAsset()"
           (mouseenter)="onSaveContainer=true"
           (mouseleave)="onSaveContainer=false">
        <span *ngIf="!onSaveContainer">{{isDoc ? "Doc": "Slide"}} Saved</span>
        <img *ngIf="!onSaveContainer" class="svg-saved" src="/assets/favoris-on-full.svg"/>
        <span style="opacity: 45%" *ngIf="onSaveContainer">Remove {{isDoc ? "Doc": "Slide"}}</span>
        <img style="opacity: 45%" *ngIf="onSaveContainer" class="svg-saved" src="/assets/favoris-off.svg"/>
      </div>
    </div>
    <img
      alt="slide" class="slide-ok"
      *ngIf="thumbnails.getThumbnail(assetElement.unique_id, currentSlideIndex, true)"
      [src]="thumbnails.getThumbnail(assetElement.unique_id, currentSlideIndex, true)">
    <div
      *ngIf="!thumbnails.getThumbnail(assetElement.unique_id, currentSlideIndex, true)"
      class="pending">
      <ng-container *ngIf="assetElement.ingestion_status !== 'SUCCESS'">
        <img alt="no-slide"
             src="/assets/camera-off.svg" style="height: 56px;">
        <div class="caption medium"
             style="color: var(--text-grey-strong); width: 100%; display: block; text-align: center">
          No preview available
        </div>
      </ng-container>
    </div>
    <div class="switch-container">
      <button qm class="primary open" (click)="openAsset()">
        Open document
      </button>
      <button qm class="tertiary copy-link" (click)="copyAsset()">
        <span class="qm-icon-copy-left"></span>
        Copy link
      </button>
      <div style="flex: 1"></div>
      <div class="switch-pill">
        <div class="link" (click)="getPreviousThumbnail()">
          <span class="qm-icon-chevron-left"></span>
        </div>
        <div class="link" (click)="getNextThumbnail()">
          <span class="qm-icon-chevron-right"></span>
        </div>
        <div class="number">
          {{ currentSlideIndex + 1 }}/{{ assetElement.slides_urls.length }}
        </div>
      </div>
    </div>
    <div class="summary-container">
      <h3 style="margin-top: 16px">Summary</h3>
      <span class="body" style="text-align: justify">
        {{ assetElement.summary }}
      </span>
    </div>
  </div>

</div>
