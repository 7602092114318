import { HttpClient } from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { environment } from '../../../environments/environment';
import {Store} from "@ngrx/store";
import {selectCanAccessTool, selectUser} from "../../store/user/user.selectors";
import UserType from "@quantmetry/api-services/lib/services/auth/models/user.type";
import {ActivatedRoute, Router} from "@angular/router";

interface GetVersionResponse {
  version: string;
}

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {

  public version: GetVersionResponse = { version: 'not responding' };

  public canAccessTool: boolean = false;
  public pendingCanAccessTool: boolean = true;

  public user?: UserType;

  public pageAccessList: {
    title: string,
    subtitle: string,
    callToAction: string,
    routerLink: string,
    isBeta: boolean,
    enabled: boolean
  }[] = [
    {title: "Slide Finder", subtitle: "Converse with the knowledge hub to find the best slide for your presentation, whether by content or template", callToAction: "Start searching", routerLink: "slide-finder", isBeta: false, enabled: true},
    {title: "Doc Finder", subtitle: "Converse with the knowledge hub to find entire decks from project proposals to company points of view, by topic or industry", callToAction: "Start searching", routerLink: "doc-finder", isBeta: false, enabled: true},
    {title: "Secure ChatGPT", subtitle: "Interact with ChatGPT in a secure, fully Capgemini Invent dedicated environment", callToAction: "Start a conversation", routerLink: "secure-chatgpt", isBeta: false, enabled: true},
    {title: "RFP Assistant", subtitle: "Speed up your response process by chatting with your RFP and finding the most relevant assets", callToAction: "Provide information", routerLink: "rfp-assistant", isBeta: true, enabled: true},
    {title: "Expert Finder", subtitle: "Coming soon", callToAction: "Find experts", routerLink: "", isBeta: false, enabled: false},
  ];

  constructor(public http: HttpClient, public store: Store, public router: Router, public route: ActivatedRoute) {}

  ngOnInit(): void {
    this.http.get<GetVersionResponse>(environment.apiUrl + '/api/version').subscribe((res) => (this.version = res));
    this.pendingCanAccessTool = true;
    this.store.select(selectUser).subscribe(res => {
      this.user = res;
    });
    this.store.select(selectCanAccessTool).subscribe(res => {
      this.canAccessTool = res;
      if(this.user){
        this.pendingCanAccessTool = false;
      }
    });
  }
  navigateTo(route: string) {
    this.router.navigate([route]);
  }
}
