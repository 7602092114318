<ng-container *ngIf="!expandedAssetElement">
  <div class="top-bar">
    <button class="tertiary" qm (click)="closeDetailsPage.emit()">
      <span class="qm-icon-arrow-left"></span>{{ backText }}
    </button>
  </div>
  <div class="container">

    <div class="user-information">
      <div class="title-container">
        <div *ngIf="picture" class="picture-container">
          <img [src]="picture" alt="profile picture">
        </div>
        <div class="info-container">
          <h3 class="medium" style="margin-bottom: 4px">
            {{ user.user_name }}
          </h3>
          <div class="body2 regular">
            {{ user.grade }} - {{ user.capability_unit_l1 }}
          </div>
          <div class="body2 regular">
            {{ user.email }}
          </div>
        </div>
      </div>

      <div class="expertises-container">
        <div *ngFor="let expertise of user.expertises" class="body3 expertise-block">
          {{ expertise }}
        </div>
      </div>

      <div class="teams-contact" (click)="contactExpert(user.email)">
        <div class="teams-icon">
          <img src="/assets/teams-icon.svg" alt="teams"/>
        </div>
        <div class="body2 medium">Contact</div>
        <div class="arrow">
          <span class="qm-icon-chevron-right"></span>
        </div>
      </div>
      <div class="about-container">
        <div class="title-edit-icon">
          <h3 class="medium title-two-lines" style="margin: 0" *ngIf="editingDescription">
            About
          </h3>
          <div *ngIf="!user.description && editingDescription" class="edition">
            <button (click)="navigateToMyprofile()" qm
                    class="tertiary" icon="edit-03">
              <div>
                Edit
              </div>
            </button>
          </div>
        </div>
        <div *ngIf="user.description">
          {{ user.description }}
        </div>
        <div *ngIf="!user.description && editingDescription"
             style="color: var(--grey-400-color)"> No information provided.
        </div>
      </div>

    </div>


    <div class="shared-asset-element">
      <app-asset-elements-container
        [assetElements]="assets"
        [displayLoadingMoreElements]="false"
        (viewDetails)="viewElementDetails($event)"
        [mode]="'Asset Retriever'"
        [displayTwoColumns]="true"
      >
      </app-asset-elements-container>
    </div>
  </div>
</ng-container>
<app-asset-element-details
  *ngIf="expandedAssetElement"
  [isDoc]="true"
  [assetElement]="expandedAssetElement.assetElement"
  [displayAfterAndBackButtons]="true"
  [backButtonText]="'Back to expert details'"
  (back)="backToPrincipalPage()"
>
</app-asset-element-details>
