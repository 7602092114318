import {createReducer, on} from "@ngrx/store";
import {SavedIkhDoc, SavedIkhSlide} from "../../services/api/saved/model/model";
import {fetchSaved, saveDoc, saveSlide, setSaved, unsaveDoc, unsaveSlide} from "./saved.actions";

export interface SavedState {
  slides: SavedIkhSlide[];
  docs: SavedIkhDoc[];
}

const initialState: SavedState = {
  slides: [],
  docs: []
};

export const savedReducer = createReducer<SavedState>(
  initialState,
  on(fetchSaved, (state) => ({...state})),
  on(setSaved, (state, {slides, docs}) => ({...state, slides, docs})),
  on(saveSlide, (state, {document_id, slide_number}) => ({
    ...state,
    slides: [...state.slides, {id: 0, document_id, slide_number}]
  })),
  on(saveDoc, (state, {document_id}) => ({
    ...state,
    docs: [...state.docs, {id: 0, document_id}]
  })),
  on(unsaveSlide, (state, {document_id, slide_number}) => ({
    ...state,
    slides: state.slides.filter(slide => !(slide.document_id === document_id && slide.slide_number === slide_number))
  })),
  on(unsaveDoc, (state, {document_id}) => ({
    ...state,
    docs: state.docs.filter(doc => doc.document_id !== document_id)
  }))
);
