<div *ngIf="!expandedAssetElement" class="asset-retriever">
  <app-header
    [headerTitle]="'Doc Finder'"
    [headerDescription]="'Converse with the knowledge hub to find entire decks from project proposals to company points of view, by topic or industry.'"
    [headerBackgroundColor]="'assetRetriever'"
    [goBackButtonAvailable]="!!rfpId"
    (navigate)="navigateToRfp()"
    [minimized]="assets && assets.length > 0"

  >
  </app-header>
  <div class="bottom-container">
    <div *ngIf="!isPending && !query_abstract" class="middle-bottom-container">
      <div class="empty-container"></div>
      <div *ngIf="!lastSearchReturnedError" class="middle-bottom-text">
        <div>
          Prompt example  <br/> <br/> <i> "I'm looking for a market study on trends and regulations in the automotive sector."</i>
        </div>
      </div>
      <div *ngIf="lastSearchReturnedError" class="middle-bottom-text error">
        <span class="qm-icon-alert-circle" style="height: 35px"></span>
        Just AI was unable to find any asset. Please, try another search.
      </div>
      <div class="empty-container">
      </div>
    </div>
    <div *ngIf="isPending || query_abstract" class="middle-bottom-container" id="middle-bottom-container">
      <app-asset-elements-container
        [assetElements]="assets"
        [pending]="isPending"
        [searchMoreElements]="searchMoreElements"
        [isLoadingMoreElements]="isLoadingMoreElements"
        [mode]="'Asset Retriever'"
        (getNext)="getMoreElements()"
        (viewDetails)="viewElementDetails($event)"
        (logOpenDocument)="logOpenDocument($event)"
        [yearFilter]="year_filter"
        (uploadDocNavigation)="navigateToUpload()"
      ></app-asset-elements-container>
    </div>
  </div>
  <div class="bottom-chat-container">
    <app-search-asset-elements
      [resetButtonText]="resetButtonText"
      [isPending]="isPending"
      [placeholder]="placeholder"
      [query]="display_query"
      (sendMessage)=postAssetRetriever($event)
      (reset)=resetSearch()
      (refine)="refineSearch()"
    >
    </app-search-asset-elements>
  </div>
</div>
<app-asset-element-details
  *ngIf="expandedAssetElement"
  [assetElement]="expandedAssetElement.assetElement"
  [displayAfterAndBackButtons]="true"
  [backButtonText]="'Back to Doc finder'"
  (back)="backToPrincipalPage()"
  (logOpenDocument)="logOpenDocument($event)"
>
</app-asset-element-details>
