import {createAction, props} from "@ngrx/store";
import {UserProfile} from "../../services/api/user-profile/model/model";

export const fetchUsers = createAction(
  '[Users] fetch users'
);

export const setUsers = createAction(
  '[Users] set users',
  props<{ users: UserProfile[] }>()
);



export const createFetchUsersAction = () => fetchUsers();
export const createSetUsersAction = (users: UserProfile[]) => setUsers({users});
