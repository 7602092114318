import {Component, Input, OnInit} from '@angular/core';
import {DocFinderPageComponent} from "../../../doc-finder-page/doc-finder-page.component";
import {HttpClient} from "@angular/common/http";
import {DocFinderService} from "../../../../services/api/doc-finder/doc-finder.service";
import {AdminService} from "../../../../services/api/admin/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationStrategy} from "@angular/common";
import {GetRfpResponse} from "../../../../services/api/rfp-assistant/model/model";
import {Store} from "@ngrx/store";

@Component({
  selector: 'app-rfp-doc-finder',
  templateUrl: './rfp-doc-finder.component.html',
  styleUrls: ['./rfp-doc-finder.component.scss']
})
export class RfpDocFinderComponent extends DocFinderPageComponent implements OnInit {
  @Input() rfpDetails: GetRfpResponse;
  pending: boolean = false;

  constructor(
    http: HttpClient,
    docFinderService: DocFinderService,
    adminService: AdminService,
    router: Router,
    route: ActivatedRoute,
    store: Store,
    location: LocationStrategy
  ) {
    super(http, docFinderService, adminService, router, route, store, location)
  }

  override ngOnInit(): void {
    if (this.rfpDetails) {
      this.pending = true;
      this.getAssetsFromRfp(this.rfpDetails.rfp.id);
    }
  }

  navigateToAssetRetriever() {
    this.router.navigate(["/doc-finder/", this.rfpDetails.rfp.id])
  }


}
