import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GetUserProfileResponse, PatchUserProfileRequest, UserProfile, UserSharedAssets} from "./model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    public http: HttpClient
  ) { }

  getUserInfo(): Observable<UserProfile> {
    return this.http.get<UserProfile>(environment.apiUrl + "/profile/me");
  }

  patchUserProfile(body:PatchUserProfileRequest): Observable<void> {
    return this.http.patch<void>(
      environment.apiUrl + "/profile/edit", body
    )
  }

  getSharedAssets(): Observable<UserSharedAssets> {
    return this.http.get<UserSharedAssets>(environment.apiUrl + "/profile/assets");
  }

  public getUsers(): Observable<GetUserProfileResponse> {
    return this.http.get<GetUserProfileResponse>(environment.apiUrl + "/profile");
  }
}
