export enum PopinState {
  EditMetadata,
  UploadingFile,
  Done
}

export interface FormComponent {
  content: string;
  valid?: boolean;
  isValid: (value: string) => boolean;
  showDropdown?: boolean;
}
