import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {
  PostAssetElementLoadMoreRequest,
  PostAssetElementResponse
} from "../slide-finder/model/model";
import {GetRfpAssetsResponse} from "../rfp-assistant/model/model";


@Injectable({
  providedIn: 'root'
})
export class DocFinderService {

  constructor(public http: HttpClient) {
  }

  postAssetRetriever(messages: string[], yearFilter: string, limit: number): Observable<PostAssetElementResponse> {
    let options = {params: new HttpParams().set("limit", limit)};
    return this.http.post<PostAssetElementResponse>(
      environment.apiUrl + "/ikh-proposal/doc-finder",
      {messages: messages, year_filter: yearFilter},
      options
    );
  };

  postAssetRetrieverLoadMore(query_abstract: string, vector_abstract: number[], search_id: number, year_filter: string, limit: number, skip: number = 0): Observable<PostAssetElementResponse> {
    let options = {params: new HttpParams().set("skip", skip).set("limit", limit)};
    let body: PostAssetElementLoadMoreRequest = {query_abstract, vector_abstract, search_id, year_filter}
    return this.http.post<PostAssetElementResponse>(
      environment.apiUrl + "/ikh-proposal/doc-finder/load-more",
      body,
      options
    );
  };

  getAssetsFromRfp(RfpId: number, limit: number): Observable<GetRfpAssetsResponse> {
    let options = {params: new HttpParams().set("limit", limit)};
    return this.http.get<GetRfpAssetsResponse>(
      environment.apiUrl + '/rfp-assistant/' + RfpId + "/rfp-assets",
      options
    );
  };
}
