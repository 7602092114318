import {Component, Input, OnInit} from '@angular/core';
import {ExpertFinderComponent} from "../../../expert-finder/expert-finder.component";
import {UserProfileService} from "../../../../services/api/user-profile/user-profile.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationStrategy} from "@angular/common";

@Component({
  selector: 'app-rfp-expert-finder',
  templateUrl: './rfp-expert-finder.component.html',
  styleUrls: ['./rfp-expert-finder.component.scss']
})
export class RfpExpertFinderComponent extends ExpertFinderComponent implements OnInit {
  @Input() override rfpId: number;

  constructor(
    userProfileService: UserProfileService,
    route: ActivatedRoute,
    router: Router,
    location: LocationStrategy
  ) {
    super(userProfileService, route, router, location);
  }
  override ngOnInit(): void {
    if (this.rfpId) {
      this.getExpertsFromRfp()
    }
  }

  navigateToExpertFinder() {
    this.router.navigate(["/expert-finder/", this.rfpId])
  }

}
