import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RatingValue} from "../../services/api/slide-finder/model/model";

@Component({
  selector: 'app-search-asset-elements',
  templateUrl: './search-asset-elements.component.html',
  styleUrls: ['./search-asset-elements.component.scss']
})
export class SearchAssetElementsComponent implements OnInit {

  MaxLength: number = 2000;
  @Input() resetButtonText?: string;
  @Input() isPending: boolean;
  @Input() placeholder: string;
  @Input() query?: string;
  @Input() disabledYearFilter: boolean;
  @Output() sendMessage: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() reset: EventEmitter<void> = new EventEmitter<void>();
  @Output() refine: EventEmitter<void> = new EventEmitter<void>();
  checkFilterYear = "";
  isPositiveClicked = false;
  isNegativeClicked = false;
  link = "doc-finder";
  isButtonRadioDisabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  postAsset(question:string, checkFilterYear: string){
    this.sendMessage.emit([question, checkFilterYear]);
    this.isPositiveClicked = false;
    this.isNegativeClicked = false;

  }
  resetConversation(reset: any){
    this.reset.emit(reset);
    this.checkFilterYear = "";
    this.isButtonRadioDisabled = false;
  }

  refineConversation(refine: any) {
    this.refine.emit(refine);
    this.isButtonRadioDisabled = true;
  }
}
