<div *ngIf="!expandedAssetElement">
  <app-header
    [headerTitle]="'Slide Finder'"
    [headerDescription]="'Converse with the knowledge hub to find the best slide for your presentation, whether by content or template.'"
    [headerBackgroundColor]="'slideFinder'"
    [minimized]="slides && slides.length > 0"

  >
  </app-header>
  <div class="bottom-container">
    <div *ngIf="!isPending && !query_abstract" class="middle-bottom-container">
      <div class="empty-container"></div>
      <div *ngIf="!lastSearchReturnedError" class="middle-bottom-text">
        <div>
          Prompt example
          <br/>
          <br/>
          <i>"The slide I'm looking for is probably part of a deck on the digital transformation of the legal profession. It contains a change roadmap and comes from the L1 NFAI capability unit."</i>

          <br/>
          <br/>
          <div class="access-saved" *ngIf="hasSavedSlides" (click)="navigateToSavedSlides()">
            <img style="margin-top: -2px" class="svg-saved" src="/assets/favoris-on-gray.svg"/>
            <span>Saved Slides</span>
          </div>
        </div>
      </div>
      <div *ngIf="lastSearchReturnedError" class="middle-bottom-text error">
        <span class="qm-icon-alert-circle" style="height: 35px"></span>
        Just AI was unable to find any slides. Please, try another search.
      </div>
      <div class="empty-container"></div>
    </div>
    <div *ngIf="isPending || query_abstract" class="middle-bottom-container" id="middle-bottom-container">
      <app-asset-elements-container
        [assetElements]="slides"
        [mode]="'Slide Finder'"
        [pending]="isPending"
        [searchMoreElements]="searchMoreElements"
        [isLoadingMoreElements]="isLoadingMoreElements"
        (getNext)="getMoreElements()"
        (viewDetails)="viewElementDetails($event)"
        (logOpenDocument)="logOpenDocument($event)"
        [yearFilter]="yearFilter"
        (uploadDocNavigation)="navigateToPage('upload-docs/')"
      ></app-asset-elements-container>
    </div>
    <div class="bottom-chat-container">
      <app-search-asset-elements
        [resetButtonText]="resetButtonText"
        [isPending]="isPending"
        [placeholder]="placeholder"
        [query]="display_query"
        (sendMessage)=postSlideFinder($event)
        (reset)=resetSearch()
        (refine)="refineSearch()"
      >
      </app-search-asset-elements>
    </div>
  </div>
</div>
<app-asset-element-details
  *ngIf="expandedAssetElement"
  [isDoc]="false"
  [assetElement]="expandedAssetElement.assetElement"
  [backButtonText]="'Back to slide finder'"
  [displayAfterAndBackButtons]="false"
  (back)="backToPrincipalPage()"
  (logOpenDocument)="logOpenDocument($event)"
>
</app-asset-element-details>
