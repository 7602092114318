import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  AssetElement,
  AssetElementWithIndex,
} from "../../services/api/slide-finder/model/model";
import {HttpClient} from "@angular/common/http";
import {DocFinderService} from "../../services/api/doc-finder/doc-finder.service";
import {AdminService} from "../../services/api/admin/admin.service";
import {AssetElementLog} from "../../services/api/admin/model/model";
import {ActivatedRoute, Router} from "@angular/router";
import {LocationStrategy} from "@angular/common";
import {Store} from "@ngrx/store";
import {hasSavedDocs} from "../../store/saved/saved.selectors";

@Component({
  selector: 'app-doc-finder-page',
  templateUrl: './doc-finder-page.component.html',
  styleUrls: ['./doc-finder-page.component.scss']
})
export class DocFinderPageComponent implements OnInit {
  resetButtonText?: string;
  placeholder: string = "Describe the deck you're looking for with an elaborate prompt...";
  assets: AssetElement[] = [];
  messages: string[] = [];
  isPending: boolean = false;
  expandedAssetElement?: AssetElementWithIndex;
  isLoadingMoreElements: boolean = false;
  numberOfElementsToRetrieve: number = 6;
  display_query: string = "";
  query_abstract: string = "";
  vector_abstract: number[] = [];
  search_id: number = -1;
  lastSearchReturnedError: boolean = false;
  year_filter: string = "";
  searchMoreElements = true;
  rfpId?: number;
  viewDetailsPosition = 0;
  hasSavedDocs: boolean = false;
  @Output() openDetailsPage: EventEmitter<AssetElementWithIndex> = new EventEmitter<AssetElementWithIndex>();
  @Output() closeDetailsPage: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public http: HttpClient,
    public docFinderService: DocFinderService,
    public adminService: AdminService,
    public router: Router,
    public route: ActivatedRoute,
    public store: Store,
    public location: LocationStrategy
  ) {
    history.pushState(null, '', window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, '', window.location.href);
      this.backToPrincipalPage();
    });
  }

  ngOnInit(): void {
    const rfpId = this.route.snapshot.paramMap.get('id');
    if (rfpId !== null && rfpId !== undefined) {
      this.rfpId = +rfpId;
      this.getAssetsFromRfp(+rfpId);
    }
    this.store.select(hasSavedDocs).subscribe(res => {
      this.hasSavedDocs = res;
    })
  }

  postAssetRetriever(body: string[]) {
    let message: string = body[0];
    let yearFilter: string = body[1];
    this.year_filter = yearFilter;
    this.messages = this.messages.concat(message)
    this.isPending = true;
    this.docFinderService.postAssetRetriever(this.messages, yearFilter, this.numberOfElementsToRetrieve).subscribe({
      next: (res) => {
        this.lastSearchReturnedError = false;
        this.assets = res.elements;
        this.display_query = "Here is what I found about " + message;
        this.query_abstract = res.query_abstract;
        this.vector_abstract = res.vector_abstract;
        this.search_id = res.search_id;
        this.isPending = false;
        this.resetButtonText = "New search";
        this.placeholder = "You can refine your search by adding new information";

      },
      error: (err) => {
        this.isPending = false;
        this.resetSearch();
        this.lastSearchReturnedError = true;
      }
    });
  }

  resetSearch() {
    this.resetButtonText = undefined;
    this.messages = []
    this.query_abstract = "";
    this.display_query = "";
    this.search_id = -1;
    this.assets = [];
    this.placeholder = "Describe the deck you're looking for with an elaborate prompt...";
    this.year_filter = "";
  }

  refineSearch() {
    this.display_query = "";
    this.resetButtonText = "New search";
  }

  getMoreElements() {
    this.isLoadingMoreElements = true;
    this.docFinderService.postAssetRetrieverLoadMore(
      this.query_abstract,
      this.vector_abstract,
      this.search_id,
      this.year_filter,
      this.numberOfElementsToRetrieve,
      this.assets.length
    ).subscribe((res) => {
      this.searchMoreElements = res.elements.length === this.numberOfElementsToRetrieve
      this.search_id = res.search_id;
      if (this.assets) {
        this.assets = this.assets.concat(res.elements)
      }
      this.isLoadingMoreElements = false;
    });
  }

  viewElementDetails(event: AssetElementWithIndex) {
    this.viewDetailsPosition = window.scrollY;
    this.openDetailsPage.emit(event);
    this.expandedAssetElement = event;
    window.scrollTo(0, 0);
    this.adminService.postSearchActionLog(
      event.assetElement.sharepoint_uri,
      event.assetElement.slide_index,
      this.search_id,
      "view_details",
      "Asset Retriever"
    );
  }

  backToPrincipalPage() {
    this.expandedAssetElement = undefined;
    this.closeDetailsPage.emit();
    setTimeout(() => {
      window.scrollTo(0, this.viewDetailsPosition);
    }, 0);
  }

  logOpenDocument(event: AssetElementLog) {
    this.adminService.postSearchActionLog(
      event.sharepoint_uri,
      event.slide_index,
      this.search_id,
      "open_document",
      "Asset Retriever"
    );
  }

  getAssetsFromRfp(rfpId: number) {
    this.isPending = true;
    this.docFinderService.getAssetsFromRfp(rfpId, 6).subscribe({
      next: (res) => {
        this.assets = res.elements;
        this.query_abstract = res.query_abstract;
        this.vector_abstract = res.vector_abstract;
        this.search_id = res.search_id;
        this.isPending = false;
      },
      error: (err) => {
        console.error('Error fetching RFP assets', err);
      }
    })
  }

  navigateToRfp() {
    if (this.rfpId) {
      this.router.navigate(["rfp-assistant/" + this.rfpId]);
    }
  }

  navigateToUpload() {
    this.router.navigate(["upload-docs/"])
  }

  navigateToSavedDocs() {
    this.router.navigate(["saved-assets"], {queryParams: {tab: 'Docs'}})
  }
}
