<div class="middle-placeholder" #messages>
  <div class="middle-side-container"></div>
  <div class="middle-container">
    <div class="empty-container" *ngIf="!conversation || !conversation.messages.length">
      <div class="icon-container">
        <img src="assets/logo.svg" alt="icon">
      </div>
      <div class="title-container">
        Chat with your RFP to ask questions about the project, its deliverables, deadlines and more.
      </div>
    </div>
    <div *ngFor="let message of conversation.messages; let last = last;">
      <div *ngIf="message.isUser" class="question-container">
        <div *ngIf="userPicture" class="profile-picture"><img [src]="userPicture" alt="profile userPicture">
        </div>
        <div *ngIf="!userPicture" class="profile-picture"><span
          class="qm-icon-user-profile-circle profile-picture-qmtopbar"></span></div>
        <div class="question-bubble-container">
          {{ message.text }}
        </div>
      </div>
      <div *ngIf="isAnswerPending && last" class="spinner">
        <qmspinner></qmspinner>
      </div>
      <div *ngIf="!message.isUser" class="answer-container">
        <div class="ai-icon">
          <img src="/assets/logo-black.svg" alt="just-ai">
        </div>
        <div class="answer-right-container">
          <markdown class="answer-bubble-container">
            {{ message.text }}
          </markdown>
          <div *ngIf="!message.isUser && last" class="answer-right-bottom-container">
            <div class="options-container">
              <button class="reload-button" (click)="resendLastMessage()" icon="arrow-refresh-01" qm></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="lastQuestionReturnedError" class="answer-container">
      <span class="qm-icon-alert-circle error"></span>
      <div class="error">
        Sorry, but Just AI was unable to find an answer to your question. Please, try again or reformulate.
      </div>
    </div>
  </div>
  <div class="middle-side-container"></div>
</div>
<app-chat-llm
  [questionMaxLength]="MaxLength"
  [placeholder]="placeholder"
  (sendMessage)=askQuestion($event)
  [areFilterDateRadiosDisplayed]="false"
  [isDarkMode]="false"
  [canSendMessage]="canSendMessage"
>
</app-chat-llm>
