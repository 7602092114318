<app-header
  [headerTitle]="'RFP Assistant'"
  [headerDescription]="'Speed up your response process by chatting with your RFP and finding the most relevant assets'"
  [headerBackgroundColor]="'savedAssets'"
  [minimized]="true"
  *ngIf="!expandedAssetElement"
>
</app-header>
<div class="qm-page">
  <div class="saved-asset-main-container" *ngIf="!expandedAssetElement">
    <div class="subtitle">
      Saved content
    </div>
    <div class="bottom-container">
      <div class="tabs">
        <div class="tab" [ngClass]="{active: selectedTab === 'Docs'}" (click)="clickOnSelectTab('Docs')">
          Doc Finder
        </div>
        <div class="tab" [ngClass]="{active: selectedTab === 'Slide'}" (click)="clickOnSelectTab('Slide')">
          Slide Finder
        </div>
      </div>
    </div>
    <div class="content" *ngIf="selectedTab==='Docs'">
      <app-asset-element-snippet [assetElement]="asset"
                                 [blueHover]="true"
                                 style="width: 320px"
                                 [mode]="'Saved Doc'"
                                 (viewDetails)="setViewDetails(asset)"
                                 *ngFor="let asset of assetElements">
      </app-asset-element-snippet>
    </div>
    <div class="content" *ngIf="selectedTab==='Slide'">
      <app-asset-element-snippet [assetElement]="asset"
                                 [blueHover]="true"
                                 style="width: 252px"
                                 [mode]="'Saved Slide'"
                                 (viewDetails)="setViewDetails(asset)"
                                 *ngFor="let asset of assetElements">
      </app-asset-element-snippet>
    </div>
  </div>
</div>
<app-asset-element-details
  *ngIf="expandedAssetElement"
  [isDoc]="selectedTab === 'Docs'"
  [assetElement]="expandedAssetElement"
  [displayAfterAndBackButtons]="true"
  [backButtonText]="'Back to saved assets'"
  (back)="closeViewDetails()"
>
</app-asset-element-details>
