import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  GetUserProfileResponse,
  PatchUserProfileRequest, PostExpertFinderLoadMoreRequest,
  PostExpertFinderResponse,
  UserProfile,
  UserSharedAssets
} from "./model/model";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    public http: HttpClient
  ) { }

  getUserInfo(): Observable<UserProfile> {
    return this.http.get<UserProfile>(environment.apiUrl + "/profile/me");
  }

  patchUserProfile(body:PatchUserProfileRequest): Observable<void> {
    return this.http.patch<void>(
      environment.apiUrl + "/profile/edit", body
    )
  }

  getSharedAssets(): Observable<UserSharedAssets> {
    return this.http.get<UserSharedAssets>(environment.apiUrl + "/profile/assets");
  }

  getUsers(): Observable<GetUserProfileResponse> {
    return this.http.get<GetUserProfileResponse>(environment.apiUrl + "/profile");
  }

  postExpertFinder(messages: string[]): Observable<PostExpertFinderResponse> {
    return this.http.post<PostExpertFinderResponse>(
      environment.apiUrl + "/profile/expert-finder", {messages, year_filter:""}
    );
  }

  postExpertFinderLoadMore(body: PostExpertFinderLoadMoreRequest, limit: number, skip: number = 0): Observable<PostExpertFinderResponse> {
    let options = {params: new HttpParams().set("skip", skip).set("limit", limit)};
    return this.http.post<PostExpertFinderResponse>(
      environment.apiUrl + "/profile/expert-finder/load-more",
      body,
      options
    );
  }

  getExpertsFromRfp(RfpId: number, limit: number): Observable<PostExpertFinderResponse> {
    let options = {params: new HttpParams().set("limit", limit)};
    return this.http.get<PostExpertFinderResponse>(environment.apiUrl + '/rfp-assistant/' + RfpId + '/rfp-experts', options);
  }
}
