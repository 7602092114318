<div class="top-container" [ngStyle]="{height: minimized? '100px': '380px', 'padding-bottom': minimized? 0 : '40px'}"
     [ngClass]="{
       rfpAssistant: headerBackgroundColor === 'rfpAssistant',
       slideFinder: headerBackgroundColor === 'slideFinder',
       assetRetriever: headerBackgroundColor === 'assetRetriever',
       uploadDocuments: headerBackgroundColor === 'uploadDocuments'
     }">
  <div class="left" *ngIf="!minimized">
    <button
      *ngIf="goBackButtonAvailable"
      class="secondary icon-left buttonGoBack"
      icon="arrow-left"
      qm
      (click)="navigate.emit()"
    >
      Return to RFP assistant
    </button>
    <h1>
      {{ headerTitle }}
    </h1>
  </div>
  <div class="empty-container" *ngIf="!minimized"></div>
  <h3 *ngIf="!minimized">{{ headerDescription }}</h3>
  <div class="empty-container" *ngIf="!minimized"></div>
</div>
