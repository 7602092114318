import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, mergeMap} from 'rxjs/operators';
import {UserProfileService} from "../../services/api/user-profile/user-profile.service";
import {fetchUsers, setUsers} from "./users.actions";


@Injectable()
export class UsersEffects {

  fetchUsersEffect = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUsers.type),
      mergeMap(() => this.userProfileService.getUsers()
        .pipe(map(res => setUsers({users: res.user_profiles})))
      )
    )
  );


  constructor(
    private actions$: Actions,
    private userProfileService: UserProfileService,
  ) {
  }

}
